import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

const styles = theme => ({
  card: {
    width: '5.5rem',
    height: '2.125rem',
    borderRadius: 0,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.modeColors.cardBackground,
  },
  cardBorder: {
    width: '5.25rem',
    height: '1.875rem',
    border: '0.125rem solid #f46b1e',
    boxShadow: 'none',
  },
  title: {
    marginBottom: 0,
    paddingTop: '0.3125rem',
    paddingBottom: 1,
    paddingLeft: '0.375rem',
  },
  classStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#ffa632',
    zIndex: 2,
    width: '0.625rem',
    height: '2.125rem',
  },
});

const XsProposal = ({classes, seen, proposal, proposalClass}) => (
  <Card className={classNames(classes.card, {[classes.cardBorder]: !seen})}>
    <CardActionArea
      classes={{
        root: classes.actionArea,
      }}
    >
      <h2 className={classes.title}>{proposal.number}</h2>
      {proposalClass && (
        <div
          className={classes.classStyle}
          style={{backgroundColor: proposalClass.colour}}
        />
      )}
    </CardActionArea>
  </Card>
);

XsProposal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(XsProposal);
