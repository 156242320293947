import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import cookie from 'react-cookies';
import queryString from 'query-string';

import { withStyles } from '@material-ui/core/styles';

import { addToDataBase, deleteFromDataBase } from '../redux/actions/dataBase';
import {
  filterProposals,
  setViewMode,
  setActiveView,
} from '../redux/actions/proposalsActions';
import Zoom from './Zoom';
import * as Helper from '../util/Helper.js';
import Element from './Element';
import Elements from './Elements';
import Sidebar from './Sidebar';
import Spinner from '../spinner/Spinner';
import ViewMode from '../proposals/ViewMode';

const styles = theme => ({
  elements: {
    flex: 1,
    padding: '24px',
  },
  zoomButtons: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
});

class SingleProposal extends Component {
  state = {
    proposal: null,
    zoom: 2,
    isModalOpen: false,
    singleElement: null,
    editClassesModal: false,
    date: null,
    expand: false,
    fullElement: false,
    isSingleElement: false,
  };

  zoomIn = () => {
    const { zoom } = this.state;
    if (zoom < 6) {
      this.setState({ zoom: zoom + 1 });
    }
  };

  zoomOut = () => {
    const { zoom } = this.state;
    if (zoom > 1) {
      this.setState({ zoom: zoom - 1 });
    }
  };

  componentDidMount() {
    const readOnly = process.env.REACT_APP_READ_ONLY;
    if (!readOnly) {
      const { addToDataBase, profile, userId, match } = this.props;
      const savedProposal = _.get(profile, `proposals.${match.params.id}`, {});
      const currentDate = moment().format('lll');
      if (savedProposal.date) {
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { lastSeen: savedProposal.date } },
        });
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { date: currentDate } },
        });
      } else {
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { lastSeen: currentDate } },
        });
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { date: currentDate } },
        });
      }
    }

    const proposal = this.getProposal();

    this.setState({ proposal }, this.updateViewModeState);

    // filter for nav
    // if (window.localStorage.getItem('search') && !process.env.REACT_APP_READ_ONLY) {
    //   this.props.filterProposals(window.localStorage.getItem('search'));
    // }
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      proposals: { cookies },
    } = this.props;
    const {
      location: prevLocation,
      proposals: { cookies: prevCookies },
    } = prevProps;
    const proposal = this.getProposal();
    if (location.search !== prevLocation.search) {
      this.updateViewModeState();
    }
    if (location.pathname !== prevLocation.pathname) {
      this.setState({ proposal }, this.updateViewModeState);
    }
    if (cookies) {
      const cookiesQuery = queryString.parse(cookies.replace(/; /g, '&'));
      const prevCookiesQuery = queryString.parse(prevCookies.replace(/; /g, '&'));
      if (
        proposal &&
        cookiesQuery.JUG_MODE === 'full' &&
        window.name.includes('page') &&
        !this.state.fullElement
      ) {
        setTimeout(() => this.fullElementMode(proposal), 500);
      }
      if (
        cookiesQuery.j_count !== prevCookiesQuery.j_count &&
        proposal &&
        cookiesQuery.JUG_MODE === 'full' &&
        window.name.includes('page')
      ) {
        setTimeout(
          () => this.fullElementMode(proposal, parseInt(cookiesQuery.j_count)),
          500
        );
      }
      if (
        cookiesQuery.j_proposal !== prevCookiesQuery.j_proposal &&
        proposal &&
        cookiesQuery.JUG_MODE === 'full' &&
        window.name.includes('page')
      ) {
        this.setState({ fullElement: false });
        this.props.history.push(`/proposal/${cookiesQuery.j_proposal}`);
      }
    }
  }

  fullElementMode = (proposal, count = 0) => {
    if (this.props.viewMode === 'normal') return;
    const keys = Object.keys(proposal.elements);
    const pageName = window.name;

    this.setState({ fullElement: true });
    if (count >= 0) {
      this.props.history.push({
        search: null,
      });
    }

    if (pageName === 'page 1') {
      this.openSingleElement(keys[count], keys.indexOf(keys[count]));
    }
    if (pageName === 'page 2') {
      this.openSingleElement(keys[count + 1], keys.indexOf(keys[count + 1]));
    }
    if (pageName === 'page 3') {
      this.openSingleElement(keys[count + 2], keys.indexOf(keys[count + 2]));
    }
  };

  openSingleElement = (elementKey, index) => {
    const { history } = this.props;
    const query = queryString.parse(this.props.location.search);

    if (elementKey && !query.view_mode) {
      history.push({
        search: `view_mode=true&start_key=${elementKey}&start_index=${index}`,
      });
    }
  };

  getStartKey = (start_key, start_index) => {
    const { proposal } = this.state;
    const query = queryString.parse(this.props.location.search);
    let historyObj = { search: '' };

    if (start_key && Object.keys(proposal.elements).includes(start_key)) {
      return start_key;
    } else if (start_index < Object.keys(proposal.elements).length) {
      return Object.keys(proposal.elements)[start_index];
    }
    if (query.view_mode) {
      historyObj.search = 'view_mode=true&start_index=0';
    }
    this.props.history.push(historyObj);

    return Object.keys(proposal.elements)[0];
  };

  updateViewModeState = () => {
    const { proposal } = this.state;
    const query = queryString.parse(this.props.location.search);
    const startKey = this.getStartKey(query.start_key, query.start_index);

    if (query.view_mode) {
      this.setState({
        isModalOpen: true,
        singleElement: proposal.elements[startKey],
        id: startKey,
        isSingleElement: true,
      });
    } else {
      this.setState({
        isModalOpen: false,
        isSingleElement: false,
      });
    }
  };

  closeModal = () => {
    const { history, viewMode, setViewMode } = this.props;

    // Close full view mode modal
    if (viewMode === 'full') setViewMode('normal');
    cookie.remove('JUG_MODE', { path: '/' });
    this.setState({ isModalOpen: false, fullElement: false });

    history.push({
      search: '',
    });
  };

  closeEditCalssesModal = () => this.setState({ editClassesModal: false });
  OpenEditCalssesModal = () => this.setState({ editClassesModal: true });

  getProposal = () => {
    const { proposals, match } = this.props;

    return proposals.data && proposals.data[match.params.id];
  };

  toggleSidebar = () => {
    this.setState({ expand: !this.state.expand });
  };

  toggleView = viewType => {
    this.props.setActiveView(viewType);
  };

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY;
    const {
      classes,
      proposals,
      PublicClasses,
      profile,
      match,
      proposalsData,
      selectedPhase,
      PublicKerrokantasi,
    } = this.props;
    const { expand, proposal, fullElement } = this.state;
    if (proposals.loading) {
      return <Spinner />;
    }
    const proposalId = match.params.id;
    const proposalClass = Helper.getProposalClass(
      proposalId,
      proposalsData,
      PublicClasses
    );

    return (
      <>
        {proposal && (
          <div className="d-flex">
            {/*all Elements*/}
            <div className={classes.elements}>
              <div className="d-flex justify-content-end align-items-center px-3">
                <Link
                  to="/"
                  className="d-flex justify-content-end align-items-center"
                  style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
                >
                  <ViewMode
                    toggleView={this.toggleView}
                    activeView=""
                    isSecondPhase={selectedPhase === 'phase-2'}
                  />
                </Link>

                <div className={classes.zoomButtons}>
                  <Zoom
                    zoomIn={this.zoomIn}
                    zoomOut={this.zoomOut}
                    zoomLevel={this.state.zoom}
                  />
                </div>
              </div>

              <div className="p-2 mb-4">
                <Elements
                  single
                  openSingleElement={this.openSingleElement}
                  zoom={this.state.zoom}
                  elements={proposal.elements}
                />
              </div>
            </div>

            {/* Don't use Sidebar when single element is opened */}
            {!this.state.isSingleElement && (
              <Sidebar
                expand={expand}
                proposal={proposal}
                profile={profile}
                readOnly={readOnly}
                proposalClass={proposalClass}
                proposalId={proposalId}
                toggleSidebar={this.toggleSidebar}
                OpenEditCalssesModal={this.OpenEditCalssesModal}
              />
            )}

            {/* The Element Modal */}
            <Element
              id={this.state.id}
              element={this.state.singleElement}
              elements={proposal.elements}
              proposalName={proposal.number}
              close={this.closeModal}
              isOpen={this.state.isModalOpen}
              expand={expand}
              history={this.props.history}
              noSlider={fullElement}
              isSingleElement={this.state.isSingleElement}
              PublicKerrokantasi={PublicKerrokantasi}
              proposalId={proposalId}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.firebase.auth.uid,
    proposals: state.proposals,
    profile: state.firebase.profile,
    PublicClasses: state.firestore.ordered.PublicClasses,
    proposalsData: state.firestore.ordered.proposals,
    viewMode: state.proposals.viewMode,
    selectedPhase: state.proposals.selectedPhase,
    PublicKerrokantasi: state.firestore.data.PublicKerrokantasi,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
    filterProposals: search => dispatch(filterProposals(search)),
    setViewMode: viewMode => dispatch(setViewMode(viewMode)),
    setActiveView: activeView => dispatch(setActiveView(activeView)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'PublicClasses', orderBy: ['position', 'asc'] },
    { collection: 'proposals' },
    { collection: 'PublicKerrokantasi' },
  ])
)(withSnackbar(withStyles(styles)(SingleProposal)));
