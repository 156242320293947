import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {firestoreConnect, isLoaded} from 'react-redux-firebase';
import _ from 'lodash';
import UsersDetails from './UsersDetails';

class Users extends Component {
  getUsers = () => {
    const {users} = this.props;
    if (isLoaded(users)) {
      return _.map(users, user => {
        return <UsersDetails key={user.id} user={user} />;
      });
    }
    return null;
  };

  render() {
    const getUsers = this.getUsers();
    return <div>{getUsers}</div>;
  }
}

const mapStateToProps = state => {
  return {
    users: state.firestore.ordered.users,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{collection: 'users'}])
)(Users);
