import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from './ManageKerrokantasiExternalLink';

const Comments = ({ proposalId }) => {
  return <ExternalLink proposalId={proposalId} />;
};

Comments.propTypes = {
  proposalId: PropTypes.string.isRequired,
};

export default Comments;
