import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import AdapterLink from '../util/AdapterLink';
import Button from '../inputs/Button';
import Popover from '@material-ui/core/Popover';

import {signOut} from '../redux/actions/authActions';

const styles = theme => ({
  paper: {
    backgroundColor: theme.modeColors.navBackground,
  },
  icons: {
    padding: '6px',
  },
  tooltip: {
    fontSize: '0.875rem',
    padding: '8px 12px',
  },
});

const ProfileMenu = ({classes, signOut, Email}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : null;
  return (
    <div>
      <Tooltip classes={{tooltip: classes.tooltip}} title="My profile">
        <IconButton
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.icons}
          color="inherit"
          id="my-profile"
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <div className="p-1">
          <h5 className="m-0 p-1">{Email}</h5>
          <Button
            color="primary"
            size="md"
            component={AdapterLink}
            to="/profile"
            onClick={handleClose}
          >
            Change password
          </Button>
          <div className=" d-flex pt-1 justify-content-end">
            <Button
              style={{color: '#fff'}}
              color="primary"
              variant="contained"
              size="sm"
              onClick={signOut}
            >
              Logout
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

ProfileMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ProfileMenu));
