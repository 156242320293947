import React from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import SearchBar from './Search';
//import SeenUnseen from './SeenUnseen';
import Classes from './Classes';
import PublicTags from './PublicTags';
import PrivateTags from './PrivateTags';
import HasNotes from './HasNotes';
import FilterElement from './FilterElement';

const styles = theme => ({
  root: {
    padding: '24px 32px',
    boxSizing: 'border-box',
    background: theme.modeColors.guttersGridBg,
    margin: 0,
    boxShadow: ' 0 1px 3px 0 rgba(0, 0, 0, 0.1)',
  },

  width: {
    width: '20.3%',
    marginBottom: 8,
    minWidth: 256,
  },
  textColor: {
    color: theme.modeColors.guttersGridAdvancedSearchColor,
  },
});

class GuttersGrid extends React.Component {
  state = {
    open: false,
  };

  componentDidMount() {
    const firstTimeRunning = cookie.load('firstTime');

    if (!firstTimeRunning) {
      this.setState({ open: true });
    }
  }

  handleChange = key => (event, value) => {
    this.setState({
      [key]: value,
    });
  };

  toggleSearch = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item className={classes.width}>
            <SearchBar />
          </Grid>

          {/*<Grid item className={classes.width}>
              <SeenUnseen />
            </Grid>*/}

          <Grid item className={classes.width}>
            <Classes />
          </Grid>

          <Grid item className={classes.width}>
            <PublicTags />
          </Grid>

          <Grid item className={classes.width}>
            <PrivateTags />
          </Grid>

          <Grid item className="mb-1">
            <HasNotes />
          </Grid>
        </Grid>

        <h5
          onClick={this.toggleSearch}
          className={`${classes.textColor} d-flex cursor-pointer align-items-center mt-1 mb-0`}
        >
          Advanced search by elements
          {this.state.open ? (
            <ExpandLess className="pl-1" />
          ) : (
            <ExpandMore className="pl-1" />
          )}
        </h5>
        <Collapse in={this.state.open} timeout="auto">
          <Grid className="mt-1" container alignItems="center" spacing={2}>
            <Grid item className={classes.width}>
              <FilterElement />
            </Grid>
          </Grid>
        </Collapse>
      </div>
    );
  }
}

GuttersGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GuttersGrid);
