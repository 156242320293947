import React from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '../inputs/Button';
import {addToDataBase, deleteFromDataBase} from '../redux/actions/dataBase';

function DeleteAlert({user, addToDataBase, deleteFromDataBase}) {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function deletUser(user) {
    addToDataBase('deletedUsers', user.email, {id: user.id, email: user.email});
    deleteFromDataBase('users', user.id);
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen} size="small" aria-label="Delete">
        <DeleteIcon fontSize="default" />
      </IconButton>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove account.</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove
            <b className="px-1">
              {user.firstName} {user.lastName}
            </b>
            account ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="md" variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            size="md"
            variant="contained"
            color="primary"
            onClick={() => deletUser(user)}
            autoFocus
          >
            remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(DeleteAlert);
