import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 60,
  },
  selectEmpty: {
    height: 20,
  },
}));

const SelectPhase = ({ selectedPhase, changedPhase }) => {
  const classes = useStyles();
  const isPhase2 = process.env.REACT_APP_API_PHASE_2 ? true : false;

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={selectedPhase}
        onChange={changedPhase}
        displayEmpty
        className={classes.selectEmpty}
      >
        <MenuItem value="" disabled>
          Select phase
        </MenuItem>
        <MenuItem value="phase-1">First phase</MenuItem>
        <MenuItem value="phase-2" disabled={!isPhase2}>
          Second phase
        </MenuItem>
      </Select>
    </FormControl>
  );
};

SelectPhase.propTypes = {
  selectedPhase: PropTypes.string.isRequired,
  changedPhase: PropTypes.func.isRequired,
};

export default SelectPhase;
