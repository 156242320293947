import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: '#f7f8f9',
      opacity: 0.9,
    },
  },
  tooltip: {
    backgroundColor: '#3f51b5',
    color: '#f7f8f9',
  },
  textArea: {
    opacity: 0.1,
    height: 0,
    width: 0,
    zIndex: -1,
    resize: 'none',
    border: 0,
    outline: 'none',
  },
});

const CopyToClipboard = ({ imageSrc, show }) => {
  const classes = useStyles();
  const textAreaRef = useRef();
  const [link, setLink] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);

  const isOS = () => {
    //can use a better detection logic here
    return navigator.userAgent.match(/ipad|iphone/i);
  };

  const selectText = () => {
    let range, selection;
    const el = textAreaRef;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(el.current);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      el.current.setSelectionRange(0, 999999);
    } else {
      el.current.select();
    }
  };

  const copyCodeToClipboard = async event => {
    event.stopPropagation();

    selectText();
    document.execCommand('copy');
    setCopySuccess(true);
  };

  const removeMessage = () => {
    setCopySuccess(false);
  };

  useEffect(() => {
    const getLinkToImage = async () => {
      try {
        const response = await fetch(imageSrc);
        const url = response.url;
        setLink(url);
      } catch (error) {}
    };
    getLinkToImage();
  }, [imageSrc]);

  return (
    show && (
      <>
        <div>
          <textarea className={classes.textArea} ref={textAreaRef} defaultValue={link} />
        </div>
        <Tooltip
          title={copySuccess ? 'Copied' : 'Copy link'}
          classes={{ tooltip: copySuccess ? classes.tooltip : null }}
        >
          <IconButton
            className={classes.root}
            onClick={copyCodeToClipboard}
            onMouseLeave={removeMessage}
          >
            <FileCopyIcon color="action" />
          </IconButton>
        </Tooltip>
      </>
    )
  );
};

CopyToClipboard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CopyToClipboard;
