import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {withSnackbar} from 'notistack';
import {addToDataBase} from '../redux/actions/dataBase';
import * as searchHelper from '../util/searchHelper';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from '@material-ui/core/styles';
import Button from '../inputs/Button';
import {filterProposals} from '../redux/actions/proposalsActions';
import {withRouter} from 'react-router-dom';
import NewTag from './NewTag';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    width: 432,
  },
  chipTag: {
    height: 24,
    backgroundColor: '#fff',
    padding: '0 8px',
    marginRight: 8,
    marginBottom: 8,
    fontSize: '0.75rem',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
  },
  deleteIcon: {
    marginLeft: 10,
  },
  textModeColors: {
    color: theme.modeColors.searchDetailsTitle,
  },
});

class SearchDetails extends Component {
  state = {
    isOpen: false,
    tag: '',
  };

  handleChange = e => this.setState({tag: e.target.value});

  closeModal = () => this.setState({isOpen: false});
  openModal = () => this.setState({isOpen: true});

  saveSearchAsTag = () => {
    const {data, enqueueSnackbar, addToDataBase, userId, profile} = this.props;
    const id = Math.random()
      .toString(36)
      .substr(2, 9);
    const {tag} = this.state;
    if (tag) {
      const privateTags = _.get(profile, 'PrivateTags', []);
      addToDataBase('users', userId, {PrivateTags: [...privateTags, {id, name: tag}]})
        .then(() => {
          // console.log('success');
          if (data.data) {
            _.map(data.data, (val, key) => {
              const allTags = _.get(profile, `${userId}.proposals.${key}.tags`, []);
              addToDataBase('users', userId, {
                proposals: {[key]: {tags: [...allTags, {id, name: tag}]}},
              })
                .then(() => {
                  // console.log('success');
                  this.closeModal();
                })
                .catch(err => enqueueSnackbar(err.message, {variant: 'error'}));
            });
          }
        })
        .catch(err => enqueueSnackbar(err.message, {variant: 'error'}));
    } else {
      enqueueSnackbar('Please Enter tag name', {variant: 'warning'});
    }
  };

  handleRemoveAdvancedFromSearch = (search, advanced) => {
    const {filterProposals, history} = this.props;
    const newSearh = search.split(',').filter(val => val !== advanced);
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'advanced_search',
        newSearh
      ),
    });
    filterProposals(history.location.search);
  };

  handleRemoveClassFromSearch = (search, className) => {
    const {filterProposals, history} = this.props;
    const newSearh = search.split(',').filter(val => val !== className);
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'classes',
        newSearh
      ),
    });
    filterProposals(history.location.search);
  };

  handleRemovePublicTagFromSearch = (search, tag) => {
    const {filterProposals, history} = this.props;
    const newSearh = search.split(',').filter(val => val !== tag);
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'public_tags',
        newSearh
      ),
    });
    filterProposals(history.location.search);
  };

  handleRemovePrivateTagFromSearch = (search, tag) => {
    const {filterProposals, history} = this.props;
    const newSearh = search.split(',').filter(val => val !== tag);
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'private_tags',
        newSearh
      ),
    });
    filterProposals(history.location.search);
  };

  clearAll = () => {
    const {filterProposals, history} = this.props;
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'private_tags',
        null
      ),
    });
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'public_tags',
        null
      ),
    });
    history.push({
      search: searchHelper.updateSearchQuery(history.location.search, 'classes', null),
    });
    history.push({
      search: searchHelper.updateSearchQuery(history.location.search, 'search', null),
    });
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'advanced_search',
        null
      ),
    });
    filterProposals(history.location.search);
  };

  render() {
    const {search, data, classes} = this.props;
    const searchObject = searchHelper.getSearchQuery(search);
    const {tag, isOpen} = this.state;
    if (search) {
      if (data.search) {
        return (
          <div className="border-bottom d-flex align-items-center pb-1 mb-1">
            <h5 className={`mb-0  mr-2 ${classes.textModeColors}`}>
              We found{' '}
              <span className="blue-text">
                {Object.keys(data.searchProposals).length} proposal,{' '}
                {Object.keys(data.searchElements).length} elements
              </span>{' '}
              for your search “{searchObject.search}”
            </h5>
            <Button
              style={{color: '#fff'}}
              variant="contained"
              color="primary"
              onClick={this.clearAll}
              size="sm"
            >
              Clear
            </Button>
          </div>
        );
      }
      return (
        <div>
          <div className="border-bottom mb-2 d-flex flex-wrap">
            {searchObject.advanced_search &&
              searchObject.advanced_search.split(',').map((val, i) => (
                <Chip
                  key={i}
                  label={val}
                  onDelete={() =>
                    this.handleRemoveAdvancedFromSearch(searchObject.advanced_search, val)
                  }
                  classes={{
                    root: classes.chipTag,
                    label: 'xs p-0',
                    deleteIcon: classes.deleteIcon,
                  }}
                  deleteIcon={<CloseIcon fontSize="small" />}
                />
              ))}

            {searchObject.classes &&
              searchObject.classes.split(',').map((val, i) => (
                <Chip
                  key={i}
                  label={val}
                  onDelete={() =>
                    this.handleRemoveClassFromSearch(searchObject.classes, val)
                  }
                  classes={{
                    root: classes.chipTag,
                    label: 'xs p-0',
                    deleteIcon: classes.deleteIcon,
                  }}
                  deleteIcon={<CloseIcon fontSize="small" />}
                />
              ))}

            {searchObject.public_tags &&
              searchObject.public_tags.split(',').map((val, i) => (
                <Chip
                  key={i}
                  label={val}
                  onDelete={() =>
                    this.handleRemovePublicTagFromSearch(searchObject.public_tags, val)
                  }
                  classes={{
                    root: classes.chipTag,
                    label: 'xs p-0',
                    deleteIcon: classes.deleteIcon,
                  }}
                  deleteIcon={<CloseIcon fontSize="small" />}
                />
              ))}

            {searchObject.private_tags &&
              searchObject.private_tags.split(',').map((val, i) => (
                <Chip
                  key={i}
                  label={val}
                  onDelete={() =>
                    this.handleRemovePrivateTagFromSearch(searchObject.private_tags, val)
                  }
                  classes={{
                    root: classes.chipTag,
                    label: 'xs p-0',
                    deleteIcon: classes.deleteIcon,
                  }}
                  deleteIcon={<CloseIcon fontSize="small" />}
                />
              ))}
            {(searchObject.classes ||
              searchObject.public_tags ||
              searchObject.private_tags ||
              searchObject.advanced_search) && (
              <div>
                <Button
                  style={{marginRight: '8px'}}
                  variant="outlined"
                  color="primary"
                  onClick={this.openModal}
                  size="sm"
                >
                  Save search as tag
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.clearAll}
                  size="sm"
                  style={{color: '#fff'}}
                >
                  Clear all
                </Button>
              </div>
            )}
          </div>
          <NewTag
            isOpen={isOpen}
            closeModal={this.closeModal}
            tag={tag}
            handleChange={this.handleChange}
            saveSearchAsTag={this.saveSearchAsTag}
            searchObject={searchObject}
            classes={classes}
          />
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => {
  return {
    userId: state.firebase.auth.uid,
    proposals: state.proposals,
    profile: state.firebase.profile,
    PublicClasses: state.firestore.ordered.PublicClasses,
    PublicTags: state.firestore.ordered.PublicTags,
    proposalsData: state.firestore.ordered.proposals,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {addToDataBase, filterProposals}
  )(withSnackbar(withStyles(styles)(SearchDetails)))
);
