import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '../inputs/Button';
import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
  },
});

const Modal = ({ classes, close, onSave, isOpen, title, proposal, children }) => (
  <Dialog
    onClose={close}
    aria-labelledby="Modal"
    open={isOpen}
    maxWidth="md"
    fullWidth={true}
    classes={{ paper: classes.dialogPaper }}
  >
    <div className="p-3 enable-copy">
      <div>
        <h2>{title}</h2>
        {proposal && <h3>Proposal {proposal.number}</h3>}
      </div>

      <div className="mb-2">{children}</div>

      <div className="d-flex justify-content-end">
        <Button
          style={{ marginRight: '1rem' }}
          size="md"
          variant="outlined"
          color="primary"
          onClick={close}
        >
          Close
        </Button>
        {onSave && (
          <Button size="md" variant="contained" color="primary" onClick={onSave}>
            Save
          </Button>
        )}
      </div>
    </div>
  </Dialog>
);

export default withStyles(styles)(Modal);
