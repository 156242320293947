import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import Tooltip from '@material-ui/core/Tooltip';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Button from '../inputs/Button';
import AdapterLink from '../util/AdapterLink';
import * as openSeaDragonHelper from '../util/openSeaDragonHelper';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    width: 'calc(100% - 264px)',
    position: 'fixed',
    top: 60,
    right: 264,
    bottom: 0,
    left: 0,
    backgroundColor: '#999da0',
    zIndex: 1000,
  },
  dialogPaper2: {
    borderRadius: 0,
    width: 'calc(100% - 36px)',
    position: 'fixed',
    top: 60,
    right: 264,
    bottom: 0,
    left: 0,
    backgroundColor: '#999da0',
    zIndex: 1000,
  },
  dialogPaper3: {
    borderRadius: 0,
    width: '100%',
    position: 'fixed',
    top: 60,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#999da0',
    zIndex: 1000,
  },
  container: {
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: '78px',
      height: '80vh',
    },
  },
  imageContainer: {
    width: '100%',
    margin: '0',
    background: theme.modeColors.elementImageContainer,
    flex: 1,
  },
  thumnails_carousel: {
    padding: '12px 40px 12px',
  },
  thumbnail: {
    maxHeight: '6rem',
    width: 'auto',
    margin: '0 0.5rem',
    cursor: 'pointer',
    // maxWidth: '7rem',
  },
  thumbnailActive: {
    maxHeight: '6rem',
    width: 'auto',
    margin: '0 0.5rem',
    cursor: 'pointer',
    // maxWidth: '7rem',
    border: '3px solid #3f51b5',
  },
  zoombuttons: {
    position: 'absolute',
    zIndex: 100,
    left: 24,
    top: 16,

    '& button': {
      paddingBottom: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 100,
    right: 24,
    top: 16,
    padding: 8,
    backgroundColor: '#fff',
  },
  carousel: {
    boxShadow: ' 0 -1px 3px 0 rgba(0, 0, 0, 0.2)',
    background: theme.modeColors.carouselBg,
  },
  proposalName: {
    fontSize: '1rem',
  },
});

let viewer;

class ManageTags extends React.Component {
  state = {
    id: '',
    link: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { reactAppUrl, location } = this.props;

    const query = queryString.parse(location.search);
    query.view_mode
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    if (this.props.id !== nextProps.id && nextProps.isOpen) {
      const prefixUrl = reactAppUrl + nextProps.element.prefix_url.replace(/ /g, '%20');
      const tileSources =
        reactAppUrl + nextProps.element.tileSource_url.replace(/ /g, '%20');

      this.setState(
        {
          id: nextProps.id,
          link: nextProps.link,
        },
        () => {
          if (!viewer) {
            return (viewer = openSeaDragonHelper.newViewer({
              id: nextProps.id,
              prefixUrl: prefixUrl,
              tileSources: tileSources,
            }));
          }

          openSeaDragonHelper.updateViewer({
            id: nextProps.id,
            prefixUrl: prefixUrl,
            tileSources: tileSources,
          });
        }
      );
    }
  }

  closeModal = () => {
    this.props.close();
    document.body.style.overflow = 'unset';
    viewer.destroy();
  };

  elemntsThumbnails = () => {
    const { reactAppUrl, elements, classes } = this.props;

    if (elements) {
      return Object.keys(elements).map((key, index) => {
        const value = elements[key];
        return (
          <div key={key} onClick={() => this.updateTheViewer(value, key, index)}>
            <img
              className={
                key === this.state.id ? classes.thumbnailActive : classes.thumbnail
              }
              src={reactAppUrl + value.thumbnail_url}
              alt={value.name}
            />
          </div>
        );
      });
    }
    return null;
  };

  updateTheViewer = (val, key, index) => {
    const {
      reactAppUrl,
      match,
      openSingleElement,
      comparing,
      history,
      proposals,
      changeTab,
    } = this.props;
    const proposal = _.pickBy(proposals, (proposal, key) => {
      const keys = Object.keys(proposal.elements);
      return keys.includes(this.state.id);
    });
    const proposalKeyValueArr = Object.entries(proposal).flat();

    if (!comparing) {
      if (match.params.id && key !== this.state.id) {
        // In single proposal view
        history.push({
          // of no proposal id found in match, search for proposal key as params
          pathname: `/proposal/${match.params.id}`,
          search: `view_mode=true&start_key=${key}&start_index=${index}`,
        });
      } else if (!match.params.id) {
        // In search result elements view (as modal)
        openSingleElement(val, key, proposalKeyValueArr);
      }
    } else {
      if (key !== this.state.id) {
        viewer.destroy();
        const prefixUrl = reactAppUrl + val.prefix_url.replace(/ /g, '%20');
        const tileSources = reactAppUrl + val.tileSource_url.replace(/ /g, '%20');

        this.setState({
          id: key,
        });
        setTimeout(() => {
          if (!viewer) {
            return (viewer = openSeaDragonHelper.newViewer({
              id: key,
              prefixUrl: prefixUrl,
              tileSources: tileSources,
            }));
          }

          openSeaDragonHelper.updateViewer({
            id: key,
            prefixUrl: prefixUrl,
            tileSources: tileSources,
          });
        }, 100);
      }
      // Update Proposal: name + element name when click new thumbnail
      openSingleElement(val, key, proposalKeyValueArr);
      changeTab(index);
    }
  };

  getKerrrokantasiUrl = () => {
    const { PublicKerrokantasi, proposalId } = this.props;
    const currProposal = PublicKerrokantasi ? PublicKerrokantasi[proposalId] : {};
    const kerrokantasi =
      currProposal && currProposal.kerrokantasi ? currProposal.kerrokantasi : {};
    return kerrokantasi;
  };

  render() {
    const { isOpen, classes, link, expand, noSlider, isSingleElement } = this.props;
    const elemntsThumbnails = this.elemntsThumbnails();
    const kerrokantasi = this.getKerrrokantasiUrl();
    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 3,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
      ],
    };

    return (
      <div
        className={
          expand
            ? classes.dialogPaper2
            : isSingleElement
            ? classes.dialogPaper3
            : classes.dialogPaper
        }
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <div className={classes.container}>
          {kerrokantasi.url && (
            <div className="position-relative">
              <a href={kerrokantasi.url} target="_blank" rel="noopener noreferrer">
                <Fab variant="extended" className={classes.closeButton}>
                  <Tooltip title={kerrokantasi.btnLabel}>
                    <ChatBubbleOutlineIcon
                      variant="outline"
                      style={{ fill: '#353c43' }}
                    />
                  </Tooltip>
                </Fab>
              </a>
            </div>
          )}

          <div className={classes.zoombuttons}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#fff', border: '1px solid #ccced0' }}
              size="xs"
              id="zoom-out"
            >
              <ZoomOutIcon />
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#fff',
                border: '1px solid #ccced0',
                borderLeft: 0,
              }}
              size="xs"
              id="zoom-in"
            >
              <ZoomInIcon />
            </Button>
          </div>
          <div className={classes.imageContainer} id={this.state.id}>
            {' '}
          </div>

          <div className={classes.carousel}>
            <div className="d-flex align-items-center justify-content-around pt-2 px-2 text-center">
              {this.props.element && (
                <h3 className={`mb-0 text-white ${classes.proposalName}`}>
                  Proposal: {this.props.proposalName}, {this.props.element.name}
                </h3>
              )}
              {link && (
                <Button
                  component={AdapterLink}
                  to={'/proposal/' + link}
                  variant="contained"
                  color="primary"
                  size="md"
                >
                  Go to the proposal of this element
                </Button>
              )}
            </div>

            {!noSlider && (
              <div className={classes.thumnails_carousel}>
                <Slider {...settings}> {elemntsThumbnails} </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    reactAppUrl: state.proposals.envValues.reactAppUrl,
    proposals: state.proposals.data,
  };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(ManageTags)));
