import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import * as searchHelper from '../../util/searchHelper';
import { filterProposals } from '../../redux/actions/proposalsActions';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'solid 1px #ccced0',
    position: 'relative',
    backgroundColor: theme.modeColors.inputBackground,
  },
  input: {
    marginLeft: 12,
    flex: 1,
    fontSize: '0.875rem',
    padding: '3px 4px 0px',
    backgroundColor: theme.modeColors.inputBackground,
  },
  iconButton: {
    padding: 8,
  },
  searchIcon: {
    fontSize: '1rem',
  },
  searchTooltip: {
    position: 'absolute',
    fontSize: '0.75rem',
    width: '100%',
    top: '2rem',
    background: theme.modeColors.inputBackground,
    border: '1px solid #ccced0',
    padding: '0.5rem',
    boxSizing: 'border-box',
  },
});

const SearchBar = ({ classes, history, filterProposals }) => {
  const [value, setValue] = useState('');
  const [searchTooltip, setSearchTooltip] = useState(false);

  useEffect(() => {
    const search = searchHelper.getSearchQuery(history.location.search);

    if (!search.search) setValue('');
  }, [history.location.search]);

  useEffect(() => {
    const search = searchHelper.getSearchQuery(history.location.search);

    if (search.search) setValue(search.search);
  }, [history.location.search]);

  const handleSubmit = event => {
    event.preventDefault();

    history.push({
      search: searchHelper.updateSearchQuery(history.location.search, 'search', value),
    });

    filterProposals(history.location.search);
  };

  const handleChange = event => setValue(event.target.value);

  return (
    <form id="search-proposals" onSubmit={handleSubmit}>
      <Paper className={classes.root} elevation={1}>
        <InputBase
          value={value}
          onChange={handleChange}
          className={classes.input}
          placeholder="Search ..."
          onFocus={() => setSearchTooltip(true)}
          onBlur={() => setSearchTooltip(false)}
        />
        <IconButton type="submit" className={classes.iconButton} aria-label="Search">
          <SearchIcon className={classes.searchIcon} />
        </IconButton>
        {searchTooltip && (
          <div className={classes.searchTooltip}>
            Search by <b>words</b> or proposal <b>numbers</b> by entering individual
            numbers like 2, 15, 45 or range like 34-56.
          </div>
        )}
      </Paper>
    </form>
  );
};

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  filterProposals: PropTypes.func.isRequired,
};

export default withRouter(
  connect(null, { filterProposals })(withStyles(styles)(SearchBar))
);
