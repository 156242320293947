import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import TooltipMUI from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles({
  dialogPaper: {
    alignItems: 'center',
    padding: '4rem 1rem',
  },
  tooltip: {
    fontSize: '14px',
  },
});

const strokeColors = [
  '#8884d8',
  '#61be65',
  '#f3e200',
  '#ffa632',
  '#ff4c4c',
  '#D34E24',
  '#3f51b5',
  '#61a8be',
  '#bc61be',
  '#dedede',
  '#a74c32',
  '#b6a55c',
  '#ffa8ad',
  '#710627',
  '#38726C',
  '#BAA898',
  '#A57548',
  '#34435E',
  '#AA6373',
  '#03440C',
  '#FF8552',
  '#39393A',
  '#DF3B57',
  '#717744',
];

const renderLines = (arr, opacity) =>
  arr.map((reviewer, i) => (
    <Line
      key={reviewer}
      type="monotone"
      dataKey={reviewer}
      stroke={strokeColors[i]}
      strokeWidth="2"
      strokeOpacity={opacity[reviewer]}
    />
  ));

const renderCustomAxisTick = ({ x, y, payload }) => {
  let svg = '';

  switch (payload.value) {
    case 'Overall rating':
      svg = (
        <TooltipMUI TransitionComponent={Zoom} title="Overall rating" placement="bottom">
          <svg x={x - 10} y={y + 6} width={30} height={30} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M13.833 0H2.167C1.25 0 .5.75.5 1.667v11.666C.5 14.25 1.25 15 2.167 15h11.666c.917 0 1.667-.75 1.667-1.667V1.667C15.5.75 14.75 0 13.833 0zM5.5 11.667H3.833V5.833H5.5v5.834zm3.333 0H7.167V3.333h1.666v8.334zm3.334 0H10.5V8.333h1.667v3.334z"
              />
              <path d="M-2-2.5h20v20H-2z" />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Trafic and transport':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Trafic and transport"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={30} height={30} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M11.833 12.617V5.583c0-2.325-2.175-2.833-5.008-2.908l.633-1.258h2.709V.167H1.833v1.25h3.959l-.634 1.266c-2.608.075-4.991.592-4.991 2.9v7.034c0 1.208.991 2.216 2.158 2.475L1 16.417v.416h1.858l1.667-1.666h3.142l1.666 1.666H11v-.416l-1.25-1.25h-.067c1.409 0 2.15-1.142 2.15-2.55zM6 13.917c-.692 0-1.25-.559-1.25-1.25 0-.692.558-1.25 1.25-1.25s1.25.558 1.25 1.25c0 .691-.558 1.25-1.25 1.25zm4.167-3.75H1.833V6h8.334v4.167z"
              />
              <path d="M-4-1.5h20v20H-4z" />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Construction and architecture':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Construction and architecture"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={30} height={30} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path d="M0 .5h20v20H0z" />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M14.167 9.667V3H5.833v3.333H2.5V18h6.667v-3.333h1.666V18H17.5V9.667h-3.333zm-8.334 6.666H4.167v-1.666h1.666v1.666zm0-3.333H4.167v-1.667h1.666V13zm0-3.333H4.167V8h1.666v1.667zM9.167 13H7.5v-1.667h1.667V13zm0-3.333H7.5V8h1.667v1.667zm0-3.334H7.5V4.667h1.667v1.666zM12.5 13h-1.667v-1.667H12.5V13zm0-3.333h-1.667V8H12.5v1.667zm0-3.334h-1.667V4.667H12.5v1.666zm3.333 10h-1.666v-1.666h1.666v1.666zm0-3.333h-1.666v-1.667h1.666V13z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Urban outdoor stages and urban green areas':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Urban outdoor stages and urban green areas"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={30} height={30} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path d="M-1-1.5h20v20H-1z" />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M17.475 6.142A5.83 5.83 0 0 0 11.642.308a5.83 5.83 0 0 0-5.834 5.834c0 2.891 2.1 5.283 4.859 5.741v3.284H4v-2.5h.833V9.333A.836.836 0 0 0 4 8.5H1.5a.836.836 0 0 0-.833.833v3.334H1.5v4.166h13.333v-1.666h-2.5v-3.234a5.833 5.833 0 0 0 5.142-5.791zM2.75 7.667c.692 0 1.25-.559 1.25-1.25 0-.692-.558-1.25-1.25-1.25s-1.25.558-1.25 1.25c0 .691.558 1.25 1.25 1.25z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Urban culture events and tourism':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Urban culture events and tourism"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={30} height={30} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path d="M-1-1.5h20v20H-1z" />
              <g fill="#353C43" fillRule="nonzero">
                <path d="M17.333 6L9 .167.667 6h7.5v10.833h1.666V6z" />
                <path d="M2.45 8.5l-1.633.308.683 3.642v4.383h1.667l.016-3.333h1.65v3.333H6.5v-5H3.083zM14.917 11.833H11.5v5h1.667V13.5h1.65l.016 3.333H16.5V12.45l.683-3.642L15.55 8.5z" />
              </g>
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Housing and lifestyle':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Housing and lifestyle"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={30} height={30} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path d="M-1-2.5h20v20H-1z" />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M14.833 5.25V.833h-2.5V3L9 0 .667 7.5h2.5v6.667h4.166v-5h3.334v5h4.166V7.5h2.5l-2.5-2.25zm-7.5.583c0-.916.75-1.666 1.667-1.666s1.667.75 1.667 1.666H7.333z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    case 'Business, industral sector and knowhow':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Business, industral sector and knowhow"
          placement="bottom"
        >
          <svg x={x - 10} y={y + 6} width={30} height={30} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path d="M-1-2.5h20v20H-1z" />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M3.167 4.167h1.666v6.666H3.167V4.167zm5.833 0H6.5A.836.836 0 0 0 5.667 5v5c0 .458.375.833.833.833H9A.836.836 0 0 0 9.833 10V5A.836.836 0 0 0 9 4.167zm-.833 5h-.834V5.833h.834v3.334zm5.833-5h-2.5a.836.836 0 0 0-.833.833v5c0 .458.375.833.833.833H14a.836.836 0 0 0 .833-.833V5A.836.836 0 0 0 14 4.167zm-.833 5h-.834V5.833h.834v3.334z"
              />
              <path d="M2.333 2.5h13.334v10H2.333z" />
              <path
                fill="#353C43"
                fillRule="nonzero"
                d="M.667.833v13.334h16.666V.833H.667zM2.333 12.5v-10h13.334v10H2.333z"
              />
            </g>
          </svg>
        </TooltipMUI>
      );
      break;
    default:
      svg = '';
  }

  return svg;
};

const createLegendOpacityObj = lineKeyDataArr => {
  const outputObj = {};

  lineKeyDataArr.map(
    reviewer =>
      (outputObj['opacity'] = {
        ...outputObj.opacity,
        [reviewer]: 1,
      })
  );

  return outputObj;
};

const MultipleLinesChart = ({ isOpen, close, allRates, proposal }) => {
  const classes = useStyles();
  const lineKeyDataArr = Object.keys(allRates.rates[0]).filter(e => e !== 'name');
  const legendOpacityObj = createLegendOpacityObj(lineKeyDataArr);
  const [linesOpacity, setLinesOpacity] = useState(legendOpacityObj);

  const handleMouseEnter = o => {
    // o is default object from recharts and it's availbale on mouse event
    const { dataKey } = o;

    setLinesOpacity({
      opacity: {
        ...linesOpacity.opacity,
        [dataKey]: 0.5,
      },
    });
  };

  const handleMouseLeave = o => {
    // o is default object from recharts and it's availbale on mouse event
    const { dataKey } = o;

    setLinesOpacity({
      opacity: {
        ...linesOpacity.opacity,
        [dataKey]: 1,
      },
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      fullWidth={true}
      maxWidth="md"
      style={{ height: '100%' }}
      classes={{ paper: classes.dialogPaper }}
    >
      <h3 style={{ marginBottom: '3rem' }}>Proposal {proposal.number}</h3>

      <LineChart
        width={800}
        height={400}
        data={allRates.rates}
        style={{ cursor: 'pointer' }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" interval={0} angle={30} tick={renderCustomAxisTick} />
        <YAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
        <Tooltip />
        <Legend
          layout="vertical"
          verticalAlign="top"
          align="right"
          wrapperStyle={{ right: '0', transform: 'translateX(30%)' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />

        {/* Lines */}
        {renderLines(lineKeyDataArr, linesOpacity.opacity)}
      </LineChart>
    </Dialog>
  );
};

MultipleLinesChart.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  allRates: PropTypes.object.isRequired,
  proposal: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])
    .isRequired,
};

export default MultipleLinesChart;
