import axios from 'axios';
import * as searchHelper from '../../util/searchHelper';
import _ from 'lodash';
import { filtersData, advancedData } from '../../util/Helper';

export const getProposals = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: 'FETCH_PROPOSALS_LOADING',
    });

    const dataToFetch =
      state.proposals.selectedPhase === 'phase-1'
        ? process.env.REACT_APP_API_PHASE_1
        : process.env.REACT_APP_API_PHASE_2;

    axios
      .get(dataToFetch)
      .then(data => {
        // console.log('data', data);
        dispatch({
          type: 'FETCH_PROPOSALS_SUCCESS',
          payload: data.data,
        });
      })
      .catch(err => {
        // console.log('err', err);
        dispatch({
          type: 'FETCH_PROPOSALS_ERROR',
          payload: err,
        });
      });
  };
};

const getFirebaseProposals = async (firestore, collection) => {
  try {
    const dataRef = firestore.collection(collection);
    const data = await dataRef.get();
    return _.reduce(
      data.docs,
      (result, doc, key) => {
        const proposalId = doc.id;
        const proposalData = doc.data();
        return Object.assign(result, { [proposalId]: proposalData });
      },
      {}
    );
  } catch (error) {
    console.log('Could not get proposals data from firebase:', error);
  }
};

export const filterProposals = link => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState(),
      profile = state.firebase.profile,
      initData = state.proposals.initData,
      classes = state.firestore.ordered.PublicClasses;
    const searchQuery = searchHelper.getSearchQuery(link);

    /**
     * In previous implementation, proposalsData = state.firestore.data.proposals.
     * By default, getFirestore() is async/await, so we don't have data from firebase for proposalsData and this leads to one bug
     * After user filter proposals by class name, result comes to UI. Then user reloads page and receives empty page with result 0
     * That's why I need to get data directly from firebase as below
     */
    const proposalsData = await getFirebaseProposals(firestore, 'proposals');

    const publicClass = searchQuery.classes;
    const privateTags = searchQuery.private_tags;
    const publicTags = searchQuery.public_tags;
    const hasNotes = searchQuery.has_notes;
    const show = searchQuery.show;
    const search = searchQuery.search;
    const advancedSearch = searchQuery.advanced_search;
    if (link) {
      if (search) {
        const newData = searchHelper.searchData(search, initData);
        dispatch({
          type: 'SEARCH_PROPOSALS_SUCCESS',
          payload: newData,
        });
      } else {
        if (advancedSearch) {
          let data = filtersData(
            show,
            publicClass,
            privateTags,
            publicTags,
            hasNotes,
            initData,
            profile,
            proposalsData
          );

          data =
            _.isEmpty(data) &&
            !publicClass &&
            !privateTags &&
            !publicTags &&
            !hasNotes &&
            !show
              ? initData
              : data;
          const newData = advancedData(advancedSearch, data);
          dispatch({
            type: 'ADVANCED_SEARCH_SUCCESS',
            payload: newData,
          });
        } else {
          const newData = filtersData(
            show,
            publicClass,
            privateTags,
            publicTags,
            hasNotes,
            initData,
            profile,
            proposalsData
          );
          const sortedNewData = sortedByCategory(proposalsData, newData, classes);
          dispatch({
            type: 'FILTER_PROPOSALS_SUCCESS',
            payload: sortedNewData,
          });
        }
      }
    } else {
      dispatch({
        type: 'FILTER_PROPOSALS_SUCCESS',
        payload: initData,
      });
    }
  };
};

const sortedByCategory = (firebaseData, dataToSort, firebaseClassesData) => {
  let newData = {};
  let results = [];

  // sort the proposals with class
  const classesNames = _.map(firebaseClassesData, val => val.class);
  const proposalsClass = _.pickBy(firebaseData, val => val.class);

  const sortedDataWithSameClassNameByNumber = (comparedClassName, data) => {
    let sortedData = [];

    _.forEach(proposalsClass, (value, key) => {
      if (value.class.class === comparedClassName && data[key]) {
        sortedData.push([key, data[key]]);
      }
    });

    // Ascending order
    return sortedData.sort((a, b) => (a[1].number > b[1].number ? 1 : -1));
  };

  _.forEach(classesNames, (value, index) => {
    const sortedResults = sortedDataWithSameClassNameByNumber(
      classesNames[index],
      dataToSort
    );

    results = [...results, ...sortedResults];
  });

  _.forEach(results, value => (newData[value[0]] = value[1]));

  //add the proposals without classes
  const proposalsClassKey = _.map(newData, (val, key) => key);

  _.forEach(dataToSort, (val, key) => {
    if (!proposalsClassKey.includes(key) && val) {
      newData[key] = val;
    }
  });

  return newData;
};

export const sortProposals = val => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();
    const { proposals } = state;
    const { profile } = state.firebase;
    const proposalsData = state.firestore.data.proposals;
    const classes = state.firestore.ordered.PublicClasses;

    let newData, dataToSort;

    if (proposals.search) {
      dataToSort = proposals.searchProposals;
    } else {
      dataToSort = proposals.data;
    }

    // sort number first to last
    if (val === 1) {
      newData = _.fromPairs(_.orderBy(_.toPairs(dataToSort), val => val[1].number));
    }

    // sort number last to first
    if (val === 2) {
      newData = _.fromPairs(_.orderBy(_.toPairs(dataToSort), val => -val[1].number));
    }

    // sort UnSeen First
    if (val === 3) {
      if (profile.proposals) {
        const proposals_seen = _.map(
          _.pickBy(profile.proposals, val => val.lastSeen),
          (val, key) => key
        );
        newData = _.fromPairs(
          _.orderBy(_.toPairs(dataToSort), val => proposals_seen.includes(val[0]))
        );
      } else {
        newData = dataToSort;
      }
    }

    // sort Seen First
    if (val === 4) {
      if (profile.proposals) {
        const proposals_seen = _.map(
          _.pickBy(profile.proposals, val => val.lastSeen),
          (val, key) => key
        );
        newData = _.fromPairs(
          _.orderBy(_.toPairs(dataToSort), val => !proposals_seen.includes(val[0]))
        );
      } else {
        newData = dataToSort;
      }
    }

    // sort by Category
    if (val === 5) {
      if (proposalsData) {
        newData = sortedByCategory(proposalsData, dataToSort, classes);
      } else {
        newData = dataToSort;
      }
    }

    if (proposals.search) {
      dispatch({
        type: 'SORT_SEARCH_PROPOSALS_SUCCESS',
        payload: newData,
      });
    } else {
      dispatch({
        type: 'SORT_ALL_PROPOSALS_SUCCESS',
        payload: newData,
      });
    }
  };
};

export const updateCookies = data => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_PROPOSALS_COOKIES',
      payload: data,
    });
  };
};

export const setPhaseId = phaseId => dispatch =>
  dispatch({ type: 'SET_SELECTED_PHASE', phaseId });

export const setBaseUrlToGetProposalsData = baseUrl => dispatch =>
  dispatch({ type: 'SET_BASE_URL', baseUrl });

export const setViewMode = viewMode => dispatch =>
  dispatch({ type: 'SET_VIEW_MODE', viewMode });

export const setActiveView = activeView => dispatch =>
  dispatch({ type: 'SET_ACTIVE_VIEW', activeView });
