import _ from 'lodash';

// PublicClasses Functions

export const removeClass = (
  id,
  enqueueSnackbar,
  deleteFromDataBase,
  addToDataBase,
  proposalsData
) => {
  deleteFromDataBase('PublicClasses', id)
    .then(() => {
      _.forEach(proposalsData, (value, key) => {
        const proposalClassId = _.get(value, 'class.id');
        if (proposalClassId === id.toString() && proposalClassId !== undefined) {
          addToDataBase('proposals', key, { class: null });
        }
      });
    })
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

export const removeClassFromProposal = (proposalID, addToDataBase) => {
  addToDataBase('proposals', proposalID, { class: null });
};

export const addClassToProposal = (
  publicClass,
  proposalId,
  addToDataBase,
  enqueueSnackbar,
  phaseId
) => {
  addToDataBase('proposals', proposalId, { class: { ...publicClass }, phaseId })
    .then(() => console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

export const createClass = (
  chosenColor,
  nameOfClass,
  position,
  addToDataBase,
  enqueueSnackbar
) => {
  if (!!chosenColor && !!nameOfClass) {
    const id = Date.parse(new Date()).toString();
    const data = {
      id: id,
      class: nameOfClass,
      colour: chosenColor,
      position,
    };
    addToDataBase('PublicClasses', id, { ...data })
      .then(() => console.log('success'))
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
  } else if (!nameOfClass) {
    enqueueSnackbar('Please Enter Class Name!', { variant: 'error' });
  } else if (!chosenColor) {
    enqueueSnackbar('Please Choose Colour!', { variant: 'error' });
  }
};

export const editClass = (
  id,
  nameOfClass,
  chosenColor,
  updateDataBase,
  enqueueSnackbar
) => {
  const data = {
    class: nameOfClass,
    colour: chosenColor,
  };
  updateDataBase('PublicClasses', id, { ...data })
    .then(() => console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

export const getProposalClass = (id, proposalsData, PublicClasses) => {
  const proposalData = _.filter(proposalsData, ['id', id]);

  if (proposalData[0]) {
    return _.get(proposalData[0], 'class');
  }
  return null;
};

// PublicNote Functions

export const getProposalPublicNote = (proposalsData, proposalsId) => {
  const proposalData = _.filter(proposalsData, ['id', proposalsId]);

  if (proposalData[0]) {
    return proposalData[0].textNote;
  }
  return null;
};

export const updatePublicNote = (note, proposalsId, props) => {
  const { addToDataBase, enqueueSnackbar } = props;
  let textNote = note ? note : '';
  addToDataBase('proposals', proposalsId, { textNote })
    .then(() => console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

// PublicTag Functions

export const createPublicTag = (tag, props, proposalId, proposalPublicTags) => {
  const { addToDataBase, enqueueSnackbar } = props;
  if (tag) {
    const id = Date.parse(new Date()).toString();
    const data = {
      name: tag,
      id: id,
    };
    addToDataBase('PublicTags', id, { ...data })
      .then(() => {
        console.log('success');
        addPublicTagToProposal(data, proposalId, props, proposalPublicTags);
      })
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
  } else if (!tag) {
    enqueueSnackbar('Please Enter Tag Name!', { variant: 'error' });
  }
};

export const addPublicTagToProposal = (
  publicTag,
  proposalId,
  props,
  proposalPublicTags
) => {
  const { addToDataBase, enqueueSnackbar } = props;
  const proposalPublicTagsId = proposalPublicTags
    ? proposalPublicTags.map(val => {
        if (val) {
          return val.id;
        }
        return null;
      })
    : [];

  if (!proposalPublicTagsId.includes(publicTag.id)) {
    addToDataBase('proposals', proposalId, {
      tags: [...proposalPublicTags, { id: publicTag.id, name: publicTag.name }],
    })
      .then(() => console.log('success'))
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
  } else {
    enqueueSnackbar('This tag added before', { variant: 'warning' });
  }
};

export const getProposalPublicTags = (id, proposalsData) => {
  const proposalData = _.filter(proposalsData, ['id', id]);
  if (proposalData[0]) {
    return proposalData[0].tags ? proposalData[0].tags : [];
  }
  return [];
};

export const removePublicTag = (id, props) => {
  const { enqueueSnackbar, deleteFromDataBase } = props;
  deleteFromDataBase('PublicTags', id)
    .then(() => console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

export const removePublicTagFromProposal = (
  tagId,
  proposalId,
  proposalPublicTags,
  props
) => {
  const { addToDataBase, enqueueSnackbar } = props;
  const newProposalPublicTags = _.filter(proposalPublicTags, val => val.id !== tagId);
  addToDataBase('proposals', proposalId, { tags: newProposalPublicTags })
    .then(() => console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

// PrivateNote Functions

export const getProposalPrivateNote = (profile, proposalsId) => {
  return _.get(profile, `proposals.${proposalsId}.note`, null);
};

export const updatePrivateNote = (note, proposalsId, props) => {
  const { addToDataBase, enqueueSnackbar, userId } = props;
  let textNote = note ? note : '';
  addToDataBase('users', userId, { proposals: { [proposalsId]: { note: textNote } } })
    .then(console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

// PrivateTags Functions

export const createPrivateTag = (
  tag,
  privateTags,
  proposalId,
  proposalPrivateTags,
  props
) => {
  const { userId, enqueueSnackbar, addToDataBase } = props;
  if (tag) {
    const id = Date.parse(new Date()).toString();
    const data = {
      name: tag,
      id: id,
    };
    addToDataBase('users', userId, { PrivateTags: [...privateTags, data] })
      .then(() => {
        console.log('success');
        addPrivateTagToProposal(data, proposalId, proposalPrivateTags, props);
      })
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
  } else if (!tag) {
    enqueueSnackbar('Please Enter Tag Name!', { variant: 'error' });
  }
};

export const removePrivateTag = (tagId, privateTags, props) => {
  const { userId, enqueueSnackbar, addToDataBase } = props;
  const newPrivateTags = privateTags.filter(tag => tag.id !== tagId);
  addToDataBase('users', userId, { PrivateTags: newPrivateTags })
    .then(() => console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

export const addPrivateTagToProposal = (tag, proposalId, proposalPrivateTags, props) => {
  const { userId, enqueueSnackbar, addToDataBase } = props;
  const proposalPrivateTagsId = proposalPrivateTags
    ? proposalPrivateTags.map(val => {
        if (val) {
          return val.id;
        }
        return null;
      })
    : [];

  if (!proposalPrivateTagsId.includes(tag.id)) {
    addToDataBase('users', userId, {
      proposals: {
        [proposalId]: { tags: [...proposalPrivateTags, { name: tag.name, id: tag.id }] },
      },
    })
      .then(() => console.log('success'))
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
  } else {
    enqueueSnackbar('This tag added before', { variant: 'warning' });
  }
};

export const getProposalPrivateTags = (profile, proposalsId) => {
  return _.get(profile, `proposals.${proposalsId}.tags`, []);
};

export const removePrivateTagFromProposal = (
  tagId,
  proposalId,
  proposalPrivateNoteTags,
  props
) => {
  const { userId, enqueueSnackbar, addToDataBase } = props;
  const newProposalPublicTags = _.filter(
    proposalPrivateNoteTags,
    val => val.id !== tagId
  );
  addToDataBase('users', userId, {
    proposals: { [proposalId]: { tags: newProposalPublicTags } },
  })
    .then(() => console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

export const isProposalHasNote = (proposalId, profile, proposalsData) => {
  const privateNote = _.get(profile, `proposals.${proposalId}.note`);

  const publicNote = _.get(proposalsData, `${proposalId}.note`);
  return !!privateNote || !!publicNote;
};

export const filtersData = (
  show,
  publicClass,
  privateTags,
  publicTags,
  hasNotes,
  data,
  profile,
  proposalData
) => {
  let privateTagsResults = [];
  let publicTagsResults = [];
  let classResults = [];
  let hasNotesResults = [];
  let showResults = [];

  if (publicClass) {
    _.filter(proposalData, (value, key) => {
      const publicClassArray = publicClass.split(',');

      if (value.class && publicClassArray.includes(value.class.class)) {
        classResults.push(key);
      }
      return false;
    });
    classResults.push('');
  }

  if (privateTags) {
    const privateTagsArray = privateTags.split(',');
    _.forEach(privateTagsArray, tag => {
      _.filter(profile.proposals, (value, key) => {
        if (value.tags) {
          const tagNames = value.tags.map(t => t.name);
          if (tagNames.includes(tag)) {
            privateTagsResults.push(key);
          }
          return false;
        }
        return false;
      });
    });
    privateTagsResults.push('');
  }
  if (publicTags) {
    const publicTagsArray = publicTags.split(',');
    _.forEach(publicTagsArray, tag => {
      _.filter(proposalData, (value, key) => {
        if (value.tags) {
          const tagNames = value.tags.map(t => t.name);
          if (tagNames.includes(tag)) {
            publicTagsResults.push(key);
          }
          return false;
        }
        return false;
      });
    });
    publicTagsResults.push('');
  }

  if (show) {
    if (show === 'seen') {
      _.filter(profile.proposals, (value, key) => {
        if (value.lastSeen) {
          showResults.push(key);
        }
        return false;
      });
    } else {
      showResults = _.keys(data);

      _.filter(profile.proposals, (value, key) => {
        if (value.lastSeen) {
          _.remove(showResults, n => {
            return n === key;
          });
        }
        return false;
      });
      showResults.push('');
    }
  }

  if (hasNotes) {
    _.filter(proposalData, (value, key) => {
      if (value.note) {
        hasNotesResults.push(key);
      }
      return false;
    });
  }

  const newData = getDataArray(
    privateTagsResults,
    publicTagsResults,
    classResults,
    hasNotesResults,
    showResults
  );
  return _.pickBy(data, (value, key) => newData.includes(key));
};

const getDataArray = (
  privateTagsResults,
  publicTagsResults,
  classResults,
  hasNotesResults,
  showResults
) => {
  const All = _.concat(
    privateTagsResults,
    publicTagsResults,
    classResults,
    hasNotesResults,
    showResults
  );
  return _.filter(All, val => {
    return (
      (_.includes(privateTagsResults, val) || _.isEmpty(privateTagsResults)) &&
      (_.includes(publicTagsResults, val) || _.isEmpty(publicTagsResults)) &&
      (_.includes(classResults, val) || _.isEmpty(classResults)) &&
      (_.includes(hasNotesResults, val) || _.isEmpty(hasNotesResults)) &&
      (_.includes(showResults, val) || _.isEmpty(showResults))
    );
  });
};

export const advancedData = (advanced, data) => {
  const advancedArray = advanced.split(',');
  const allElements = _.reduce(
    data,
    (result, value, key) => Object.assign(result, value.elements),
    {}
  );
  return _.pickBy(allElements, (val, key) => _.includes(advancedArray, val.name));
};

// Private Review
export const getProposalPrivateReview = (profile, proposalsId) => {
  return _.get(profile, `proposals.${proposalsId}.review`, null);
};

export const updatePrivateReview = (review, proposalsId, props) => {
  const { addToDataBase, enqueueSnackbar, userId } = props;
  let textReview = review ? review : '';
  addToDataBase('users', userId, {
    proposals: { [proposalsId]: { review: textReview } },
  })
    .then(console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

// Public Rivew

export const getProposalPublicReview = (proposalsData, proposalsId, lang) => {
  const proposalData = _.filter(proposalsData, ['id', proposalsId]);

  if (proposalData[0]) {
    return lang === 'fi' ? proposalData[0].review_fi : proposalData[0].review;
  }
  return null;
};

export const updatePublicReview = (review, proposalsId, props, lang) => {
  const { addToDataBase, enqueueSnackbar } = props;
  let textReview = review ? review : '';
  if (lang === 'en') {
    addToDataBase('proposals', proposalsId, { review: textReview })
      .then(() => console.log('success'))
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
  } else if (lang === 'fi') {
    addToDataBase('proposals', proposalsId, { review_fi: textReview })
      .then(() => console.log('success'))
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
  }
};

// Rating
export const updatePrivateRate = (rate, proposalsId, props) => {
  const { addToDataBase, enqueueSnackbar, userId } = props;
  addToDataBase('users', userId, { proposals: { [proposalsId]: { rate } } })
    .then(console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

export const getProposalPrivateRate = (profile, proposalsId) => {
  return _.get(profile, `proposals.${proposalsId}.rate`, {});
};

export const updatePublicRate = (rate, proposalsId, props) => {
  const { addToDataBase, enqueueSnackbar } = props;
  addToDataBase('proposals', proposalsId, { rate })
    .then(console.log('success'))
    .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
};

export const getProposalPublicRate = (proposalsData, proposalsId) => {
  const proposalData = _.filter(proposalsData, ['id', proposalsId]);

  if (proposalData[0]) {
    return proposalData[0].rate ? proposalData[0].rate : {};
  }
  return {};
};

export const formatRateName = name => {
  let formattedName = '';

  switch (name) {
    case 'overall':
      formattedName = 'Overall rating';
      break;
    case 'trafic_transport':
      formattedName = 'Trafic and transport';
      break;
    case 'construction_architecture':
      formattedName = 'Construction and architecture';
      break;
    case 'urban_outdoor_green_areas':
      formattedName = 'Urban outdoor stages and urban green areas';
      break;
    case 'urban_culture_events_tourism':
      formattedName = 'Urban culture events and tourism';
      break;
    case 'housing_lifestyle':
      formattedName = 'Housing and lifestyle';
      break;
    case 'business_industral_knowhow':
      formattedName = 'Business, industral sector and knowhow';
      break;

    default:
      break;
  }

  return formattedName;
};

const generateRatesData = (usersHaveRate, proposalId) => {
  const outputObj = {
    overall: {},
    trafic_transport: {},
    construction_architecture: {},
    urban_outdoor_green_areas: {},
    urban_culture_events_tourism: {},
    housing_lifestyle: {},
    business_industral_knowhow: {},
  };
  const output = [];

  _.forEach(usersHaveRate, (userInfo, key) => {
    const rateObj = userInfo.proposals[proposalId].rate;
    outputObj['business_industral_knowhow'][userInfo.firstName] =
      rateObj.business_industral_knowhow;
    outputObj['construction_architecture'][userInfo.firstName] =
      rateObj.construction_architecture;
    outputObj['housing_lifestyle'][userInfo.firstName] = rateObj.housing_lifestyle;
    outputObj['trafic_transport'][userInfo.firstName] = rateObj.trafic_transport;
    outputObj['urban_culture_events_tourism'][userInfo.firstName] =
      rateObj.urban_culture_events_tourism;
    outputObj['urban_outdoor_green_areas'][userInfo.firstName] =
      rateObj.urban_outdoor_green_areas;
    outputObj['overall'][userInfo.firstName] = rateObj.overall;
  });

  _.forEach(outputObj, (item, key) => {
    const obj = { ...{ name: formatRateName(key) }, ...item };
    output.push(obj);
  });

  return output;
};

export const getProposalAllRate = (users, proposalId) => {
  const usersCopy = [...users];
  const judges = _.filter(usersCopy, value => value.role === 'judge');
  const usersHaveRate = _.pickBy(judges, (value, key) => {
    return _.get(value, `proposals.${proposalId}.rate`, false);
  });
  if (!_.isEmpty(usersHaveRate)) {
    const rates = generateRatesData(usersHaveRate, proposalId);
    const usersRate = _.map(
      usersHaveRate,
      (value, key) => value.proposals[proposalId].rate
    );
    const userVoted = usersRate.length;
    const rate = {};
    const calcData = {
      business_industral_knowhow: {
        count: 0,
        total: 0,
      },
      construction_architecture: {
        count: 0,
        total: 0,
      },
      housing_lifestyle: {
        count: 0,
        total: 0,
      },
      overall: {
        count: 0,
        total: 0,
      },
      trafic_transport: {
        count: 0,
        total: 0,
      },
      urban_culture_events_tourism: {
        count: 0,
        total: 0,
      },
      urban_outdoor_green_areas: {
        count: 0,
        total: 0,
      },
    };

    // Count times a criterion is rated and its total value
    usersRate.forEach(rate => {
      _.map(rate, (value, key) => {
        if (value) {
          calcData[key].count++;
          calcData[key].total += value;
        }
      });
    });

    _.map(calcData, (value, key) => {
      if (value.count) rate[key] = value.total / value.count;
    });

    return { rates, rate, userVoted, users: judges.length };
  } else {
    return { rates: [], rate: {}, userVoted: 0, users: judges.length };
  }
};

export const htmToArray = html => {
  if (html) {
    const newHtml = html
      .replace(/<\s*[a-z][^>]*>/g, '')
      .replace(/\n/g, '')
      .replace(/<\/span>/g, '')
      .replace(/<\/em>/g, '')
      .replace(/<\/strong>/g, '')
      .replace(/<\/a>/g, '')
      .replace(/<\/ins>/g, '')
      .replace(/<\/del>/g, '')
      .replace(/<\/sup>/g, '')
      .replace(/<\/sub>/g, '')
      .replace(/<\/code>/g, '')
      .replace(/<\/blockquote>/g, '')
      .replace(/<\/p>/g, 'arraypoint')
      .replace(/<\/h1>/g, 'arraypoint')
      .replace(/<\/h2>/g, 'arraypoint')
      .replace(/<\/h3>/g, 'arraypoint')
      .replace(/<\/h4>/g, 'arraypoint')
      .replace(/<\/h5>/g, 'arraypoint')
      .replace(/<\/h6>/g, 'arraypoint')
      .replace(/&nbsp;/g, ' ');
    return newHtml.split('arraypoint');
  }
  return [];
};
