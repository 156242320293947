import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const styles = theme => ({
  card: {
    width: '9.125rem',
    height: '7.25rem',
    borderRadius: 0,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.modeColors.cardBackground,
  },
  cardBorder: {
    width: '8.875rem',
    height: '7rem',
    border: '0.125rem solid #f46b1e',
    boxShadow: 'none',
  },
  actionArea: {
    marginLeft: '-7px',
    marginRight: '-7px',
    width: 'calc(100% + 14px)',
    '&:hover $focusHighlight': {
      opacity: 1,
    },
  },
  cardMedia: {
    height: '5rem',
  },
  focusHighlight: {
    backgroundColor: 'rgba(247, 248, 249, 0.8)',
    height: '5rem',
  },
  classStyle: {
    position: 'absolute',
    top: 0,
    left: '7px',
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '0.25rem 0.625rem 0.125rem',
    color: '#fff',
    backgroundColor: '#ffa632',
    zIndex: 2,
  },
});

const SmProposal = ({ classes, seen, proposal, proposalClass, reactAppUrl }) => (
  <Card className={classNames(classes.card, { [classes.cardBorder]: !seen })}>
    <CardActionArea
      classes={{
        root: classes.actionArea,
        focusHighlight: classes.focusHighlight,
      }}
    >
      <CardMedia
        className={classes.cardMedia}
        component="img"
        height="80"
        image={reactAppUrl + proposal.thumbnail_url}
      />
      <CardContent className="py-1 px-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="mb-0">{proposal.number}</h2>
          <div className="sm">{seen ? '' : 'Unseen'}</div>
          {proposalClass && (
            <div
              className={classes.classStyle}
              style={{ backgroundColor: proposalClass.colour }}
            >
              {proposalClass.class}
            </div>
          )}
        </div>
      </CardContent>
    </CardActionArea>
  </Card>
);

SmProposal.propTypes = {
  classes: PropTypes.object.isRequired,
  reactAppUrl: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    reactAppUrl: state.proposals.envValues.reactAppUrl,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SmProposal));
