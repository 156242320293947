import {secondaryApp} from '../../util/fbconfig';

export const signIn = credentials => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({type: 'LOGIN_SUCCESS'});
      })
      .catch(err => {
        dispatch({type: 'LOGIN_ERROR', err});
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({type: 'SIGNOUT_SUCCESS'});
      });
  };
};

export const resetAuthEroor = () => {
  return dispatch => {
    dispatch({type: 'RESET_AUTH_ERROR'});
  };
};

export const changePassword = (currentPassword, newPassword) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);

    user
      .reauthenticateWithCredential(cred)
      .then(() => {
        user
          .updatePassword(newPassword)
          .then(() => {
            dispatch({type: 'CHANGE_PASSWORD_SUCCESS'});
          })
          .catch(err => {
            // console.log(err);
            dispatch({type: 'CHANGE_PASSWORD_ERROR', err});
          });
      })
      .catch(err => {
        dispatch({type: 'CHANGE_PASSWORD_ERROR', err});
      });
  };
};

export const setNewPassword = newPassword => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser;
    user
      .updatePassword(newPassword)
      .then(() => {
        dispatch({type: 'CHANGE_PASSWORD_SUCCESS'});
        return firestore
          .collection('users')
          .doc(user.uid)
          .update({
            newUser: false,
            demoPassword: null,
          });
      })
      .catch(err => {
        // console.log(err);
        dispatch({type: 'CHANGE_PASSWORD_ERROR', err});
      });
  };
};

export const sendPasswordResetEmail = email => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = secondaryApp;
    return firebase.auth().sendPasswordResetEmail(email);
  };
};

export const createUserWithEmailAndPassword = (email, pass) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = secondaryApp;
    return firebase.auth().createUserWithEmailAndPassword(email, pass);
  };
};

export const sendSignInLinkToEmail = email => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const actionCodeSettings = {
      url: window.location.origin + '/login',
      handleCodeInApp: true,
    };
    const firebase = secondaryApp;
    return firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
  };
};

export const isSignInWithEmailLink = href => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    return firebase.auth().isSignInWithEmailLink(href);
  };
};

export const signInWithEmailLink = (email, location) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailLink(email, location)
      .then(function() {
        dispatch({type: 'SIGN_LINK_SUCCESS'});
        // console.log('SIGN_LINK_SUCCESS');
      })
      .catch(function(err) {
        dispatch({type: 'SIGN_LINK_ERROR', err});
        // console.log('SIGN_LINK_ERROR', err);
      });
  };
};
