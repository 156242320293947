import React, { useState } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '../inputs/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ElementTabs from './ElementTabs';
import ManageClasses from './ManageClasses';

const styles = theme => ({
  sideNav: {
    position: 'sticky',
    top: props => (props.withoutHead ? 90 : 40),
    width: props => (props.expand ? 264 : 40),
    height: props => (props.withoutHead ? 'calc(100vh - 90px)' : 'calc(100vh - 40px)'),
    padding: props => (props.expand ? 16 : '16px 0'),
    borderLeft: '1px solid #e0e2e4',
    boxSizing: 'border-box',
    backgroundColor: theme.modeColors.singleProposalSideNavBg,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    overflow: 'auto',
    zIndex: 1001,
    color: theme.modeColors.singleProposalSideNavText,
    [theme.breakpoints.down('xs')]: {
      top: props => (props.withoutHead ? 90 : 138),
      width: props => (props.expand ? '100%' : 40),
      backgroundColor: props =>
        props.expand ? theme.modeColors.singleProposalSideNavBg : 'rgba(0, 0, 0, 0)',
      borderLeft: props => (props.expand ? '1px solid #e0e2e4' : 0),
      boxShadow: 'none',
      position: 'fixed',
      right: 0,
    },
  },
  proposalTitle: {
    fontSize: '2.5rem',
    fontWeight: 700,
    paddingBottom: 4,
    borderBottom: '1px solid #ccced0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      borderBottom: props => (props.expand ? '1px solid #ccced0' : 0),
      backgroundColor: props => (props.expand ? 'rgba(0, 0, 0, 0)' : '#ccced0'),
      paddingBottom: props => (props.expand ? 4 : 0),
      borderRadius: props => (props.expand ? '0' : '2px 0 0 2px'),
    },
  },
  textModeColors: {
    color: theme.modeColors.singleProposalSideNavText,
  },
});

const Sidebar = ({
  expand,
  proposal,
  proposalClass,
  profile,
  readOnly,
  classes,
  proposalId,
  toggleSidebar,
  withoutHead,
}) => {
  const [classModal, setClassModal] = useState(false);
  const lastSeen = _.get(profile, `proposals.${proposalId}.lastSeen`, null);

  return (
    <div className={classes.sideNav}>
      {/* Proposal Number */}
      {!withoutHead && (
        <div className={classes.proposalTitle}>
          {expand && <span>{proposal.number}</span>}
          <div className="cursor-pointer pt-1" onClick={toggleSidebar}>
            {expand && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title="Hide the Sidebar">
                <ChevronRight fontSize="large" />
              </Tooltip>
            )}
            {!expand && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title="Show the Sidebar">
                <ChevronLeft fontSize="large" />
              </Tooltip>
            )}
          </div>
        </div>
      )}
      {expand && (
        <div className="py-2 border-bottom">
          {/* Last seen just for not readOnly mode */}
          {!readOnly && !withoutHead && (
            <div className="d-flex align-items-center mb-1">
              <h5 className={`mb-0 ${classes.textModeColors}`}>Last seen:</h5>
              <div className="sm pl-1">{lastSeen}</div>
            </div>
          )}

          {/* Proposal name */}
          <div className="d-flex align-items-center mb-1 enable-copy">
            <h5 className={`mb-0 ${classes.textModeColors}`}>Pseudonym:</h5>
            <div
              className="sm pl-1 text-uppercase"
              style={{ wordBreak: 'break-word', hyphens: 'auto' }}
            >
              {/* {proposal.name.replace(/[0-9]/g, '')} */}
              {proposal.name}
            </div>
          </div>

          {/* Proposal class */}
          {!readOnly && (
            <div className="d-flex align-items-center enable-copy">
              <h5 className={`mb-0 ${classes.textModeColors}`}>Class:</h5>
              {proposalClass && (
                <div
                  className="small-class-container"
                  style={{ backgroundColor: proposalClass.colour }}
                >
                  {proposalClass.class}
                </div>
              )}
              {!proposalClass && profile.role !== 'judge' && !readOnly && (
                <div className={`sm pl-1 ${classes.textModeColors}`}>Add class</div>
              )}
              {!proposalClass && profile.role === 'judge' && !readOnly && (
                <div className="sm pl-1 text-gray">The secretary will add class</div>
              )}
            </div>
          )}
          {/* Manage Proposal class */}
          {profile.role !== 'judge' && !readOnly && (
            <div className="d-flex justify-content-end mt-1">
              <Button
                style={{ padding: '5px' }}
                onClick={() => setClassModal(true)}
                size="sm"
              >
                <span className={`d-flex align-items-center ${classes.textModeColors}`}>
                  <span className="mr-1">Add and manage classes </span>
                  <SettingsIcon fontSize="small" />
                </span>
              </Button>
            </div>
          )}
        </div>
      )}

      {expand && <ElementTabs proposal={proposal} proposalId={proposalId} />}

      {!readOnly && (
        <ManageClasses
          proposal={proposal}
          proposalId={proposalId}
          proposalClass={proposalClass}
          close={() => setClassModal(false)}
          isOpen={classModal}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(Sidebar);
