import React from 'react';
import {useTable, useSortBy} from 'react-table';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    position: 'relative',
    minWidth: 650,
  },
  headerCell: {
    verticalAlign: 'bottom',
    minWidth: '150px',
    backgroundColor: '#fff',
    fontWeight: 700,
  },
  stickyCell: {
    position: 'sticky',
    fontWeight: 700,
    fontSize: '1.375rem',
    color: '#000000',
    width: '100%',
  },
  stickyCell1: {
    left: '0',
    textAlign: 'center',
    maxWidth: '50px',
  },
  stickyCell2: {
    left: '82px',
    maxWidth: '100px',
  },
  stickyCell3: {
    left: '214px',
    boxShadow: ' 2px 0px 5px 0px rgba(0,0,0,0.02);',
  },
  stickyHeaderCell: {
    verticalAlign: 'bottom',
    position: 'sticky',
    backgroundColor: '#fff',
    fontWeight: 700,
    zIndex: '2',
    width: '100%',
  },
  stickyHeaderCell1: {
    left: '0',
    maxWidth: '50px',
    textAlign: 'center',
  },
  stickyHeaderCell2: {
    maxWidth: '100px',
    left: '82px',
  },
  stickyHeaderCell3: {
    left: '214px',
    boxShadow: ' 2px 0px 5px 0px rgba(0,0,0,0.02);',
  },
  tableRow: {
    '&:nth-child(odd) td': {
      backgroundColor: '#fff8ef',
    },
    '&:nth-child(even) td': {
      backgroundColor: '#fff',
    },
  },
  displayNone: {
    display: 'none',
  },
  pseudonym: {
    fontWeight: 400,
    fontSize: '0.875rem',
    hyphens: 'auto',
  },
}));

function TableStructure({columns, data}) {
  const classes = useStyles();
  // Use the state and functions returned from useTable
  const {getTableProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const assignStickyHeaderClass = headerName => {
    let result;

    if (headerName === 'No.') {
      return (result = {
        root: `${classes.stickyHeaderCell} ${classes.stickyHeaderCell1}`,
      });
    }
    if (headerName === 'Pseudonym') {
      return (result = {
        root: `${classes.stickyHeaderCell} ${classes.stickyHeaderCell2}`,
      });
    }
    if (headerName === 'Class') {
      return (result = {
        root: `${classes.stickyHeaderCell} ${classes.stickyHeaderCell3}`,
      });
    }
    if (headerName !== 'No.' && headerName !== 'Pseudonym' && headerName !== 'Class') {
      return (result = {root: classes.headerCell});
    }

    return result;
  };

  const assignStickyCellClass = index => {
    let result;

    if (index === 0) {
      return (result = {root: `${classes.stickyCell} ${classes.stickyCell1}`});
    }
    if (index === 1) {
      return (result = {
        root: `${classes.stickyCell} ${classes.stickyCell2} ${classes.pseudonym}`,
      });
    }
    if (index === 2) {
      return (result = {root: `${classes.stickyCell} ${classes.stickyCell3}`});
    }

    return result;
  };

  // Render the UI for your table
  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="simple table" {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, i) => (
            <TableRow
              classes={i === 0 ? {root: classes.displayNone} : null}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, i) => {
                return (
                  <TableCell
                    align="left"
                    classes={assignStickyHeaderClass(column.Header)}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {i !== 2 ? (
                      <TableSortLabel
                        style={{paddingTop: '10px'}}
                        direction={
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'desc'
                              : 'asc'
                            : 'desc'
                        }
                        active={column.isSorted}
                      >
                        {column.render('Header')}
                      </TableSortLabel>
                    ) : (
                      column.render('Header')
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map(
            (row, i) =>
              prepareRow(row) || (
                <TableRow classes={{root: classes.tableRow}} {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <TableCell
                        classes={assignStickyCellClass(i)}
                        align="left"
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default TableStructure;
