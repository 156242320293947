import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withSnackbar } from 'notistack';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import * as Helper from '../util/Helper.js';
import Button from '../inputs/Button';
import {
  addToDataBase,
  deleteFromDataBase,
  updateDataBase,
} from '../redux/actions/dataBase';
import SortItem from '../sort/SortItem';

import EditClass from '../manage-class/EditClass';
import CreateClass from '../manage-class/CreateClass';
import RemoveClass from '../manage-class/RemoveClass';

const styles = theme => ({
  dialogPaper: {
    position: 'relative',
    borderRadius: 0,
    width: 432,
    backgroundColor: theme.modeColors.inputBackground,
  },
  hamburgerIcon: {
    fill: '#ccc',
    '&:hover': {
      cursor: 'move',
    },
  },
  sortableHelper: {
    zIndex: '10000',
    listStyle: 'none !important',
    boxShadow: '0px 2px 5px -2px rgba(0,0,0,0.75)',
    backgroundColor: '#fff',
  },
});

const ManageClasses = ({
  close,
  isOpen,
  classes,
  PublicClasses,
  proposal,
  proposalId,
  proposalClass,
  addToDataBase,
  enqueueSnackbar,
  selectedPhase,
  deleteFromDataBase,
  proposalsData,
  updateDataBase,
}) => {
  const [chosenColor, setChosenColor] = useState('');
  const [editChosenColor, setEditChosenColor] = useState('');
  const [nameOfClass, setNameOfClass] = useState('');
  const [editNameOfClass, setEditNameOfClass] = useState('');
  const [openRemove, setOpenRemove] = useState(false);
  const [classData, setClassData] = useState({});
  const [proposalClasses, setProposalClasses] = useState([]);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editingClassData, setEditingClassData] = useState({});

  useEffect(() => {
    if (PublicClasses) {
      const cloneArray = () => {
        const copiedPublicClasses = _.map(PublicClasses, _.clone);
        const sortedCopyData = copiedPublicClasses
          .filter(element => element !== null)
          .sort((a, b) => a.position - b.position);
        setProposalClasses(sortedCopyData);
      };
      cloneArray();
    }
  }, [PublicClasses]);

  const handleChange = event => {
    const { value, name } = event.target;

    if (name === 'nameOfClass' || name === 'editNameOfClass') {
      setNameOfClass(value);
    } else if (name === 'chosenColor' || name === 'editChosenColor') {
      setChosenColor(value);
    }
  };

  const handleChangeInEditClass = event => {
    const { value, name } = event.target;

    if (name === 'editNameOfClass') {
      setEditNameOfClass(value);
    } else if (name === 'editChosenColor') {
      setEditChosenColor(value);
    }
  };

  const createClass = (
    chosenColor,
    nameOfClass,
    position,
    addToDataBase,
    enqueueSnackbar
  ) => {
    Helper.createClass(
      chosenColor,
      nameOfClass,
      position,
      addToDataBase,
      enqueueSnackbar
    );

    if (chosenColor && nameOfClass) {
      resetValues();
    }
  };

  const resetValues = () => {
    setChosenColor('');
    setNameOfClass('');
  };

  const handleCloseRemove = () => setOpenRemove(false);

  const handleOpenRemove = publicClass => {
    setOpenRemove(true);
    setClassData(publicClass);
  };

  const removeClass = () => {
    const { id } = classData;

    Helper.removeClass(
      id,
      enqueueSnackbar,
      deleteFromDataBase,
      addToDataBase,
      proposalsData
    );
    handleCloseRemove();
  };

  // const updateDatabaseHandler = () => {
  //   proposalClasses.forEach(proposalClass => {
  //     updateDataBase('PublicClasses', proposalClass.id, proposalClass);
  //   });
  //   // close();
  // };

  const saveEditClass = (
    id,
    editNameOfClass,
    editChosenColor,
    updateDataBase,
    enqueueSnackbar
  ) => {
    if (editChosenColor) {
      setEditChosenColor('');
    }
    const className = editNameOfClass ? editNameOfClass : editingClassData.class;
    const color = editChosenColor ? editChosenColor : editingClassData.colour;

    Helper.editClass(id, className, color, updateDataBase, enqueueSnackbar);
    Helper.addClassToProposal(
      { ...editingClassData, class: className, colour: color },
      proposalId,
      addToDataBase,
      enqueueSnackbar,
      selectedPhase
    );
    setIsEditDialogOpen(false);
  };

  const openEditDialogHandler = classData => {
    setEditingClassData(classData);
    setIsEditDialogOpen(!isEditDialogOpen);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedClasses = arrayMove(proposalClasses, oldIndex, newIndex);
    const updatedClassesPosition = _.map(sortedClasses, (value, index) => ({
      ...value,
      position: index + 1,
    }));
    setProposalClasses(updatedClassesPosition);

    // Update database after sort
    updatedClassesPosition.forEach(proposalClass => {
      updateDataBase('PublicClasses', proposalClass.id, proposalClass);
    });
  };

  const DragHandle = sortableHandle(() => (
    <MenuIcon classes={{ root: classes.hamburgerIcon }} />
  ));

  const SortableItem = sortableElement(({ value }) => (
    <div className="d-flex align-items-center pl-1" style={{ width: '100%' }}>
      <DragHandle />
      <SortItem
        applyClassToProposal={() =>
          Helper.addClassToProposal(
            value,
            proposalId,
            addToDataBase,
            enqueueSnackbar,
            selectedPhase
          )
        }
        proposalClass={value}
        handleOpenRemove={() => handleOpenRemove(value)}
        openEditDialog={() => openEditDialogHandler(value)}
      />
    </div>
  ));

  const SortableContainer = sortableContainer(({ items }) => (
    <List>
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} />
      ))}
    </List>
  ));

  return (
    <>
      {isEditDialogOpen && (
        <EditClass
          handleChange={handleChangeInEditClass}
          chosenColor={editChosenColor}
          close={() => setIsEditDialogOpen(false)}
          isOpen={isEditDialogOpen}
          proposalClass={editingClassData}
          saveEditClass={() =>
            saveEditClass(
              editingClassData.id,
              editNameOfClass,
              editChosenColor,
              updateDataBase,
              enqueueSnackbar
            )
          }
        />
      )}

      <Dialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="md"
        classes={{ paper: classes.dialogPaper }}
      >
        <div className="px-3 pt-3">
          <h2>Manage classes </h2>
          <div className="border-bottom d-flex align-items-center pb-2">
            <h3 className="mb-0 pr-1 py-1">Proposal {proposal.number}:</h3>
            {proposalClass && (
              <div
                className="big-class-container "
                style={{ backgroundColor: proposalClass.colour }}
              >
                {proposalClass.class}
                <IconButton
                  size="small"
                  aria-label="Delete"
                  color="inherit"
                  className="delete-class"
                  onClick={() =>
                    Helper.removeClassFromProposal(proposalId, addToDataBase)
                  }
                >
                  <CloseIcon fontSize="small" color="inherit" />
                </IconButton>
              </div>
            )}
          </div>

          <div className="pt-2 border-bottom">
            <h5 className="mb-0">Choose a class for this proposal</h5>
            {proposalClasses ? (
              <SortableContainer
                items={proposalClasses}
                onSortEnd={onSortEnd}
                useDragHandle
                helperClass={classes.sortableHelper}
              />
            ) : null}
          </div>

          <CreateClass
            nameOfClass={nameOfClass}
            handleChange={handleChange}
            chosenColor={chosenColor}
            createClass={() => {
              const position = proposalClasses.length + 1;
              createClass(
                chosenColor,
                nameOfClass,
                position,
                addToDataBase,
                enqueueSnackbar
              );
            }}
          />

          <div className="d-flex align-items-center justify-content-end py-2">
            {/* <Button
              style={{ marginLeft: '16px' }}
              size="md"
              variant="contained"
              onClick={updateDatabaseHandler}
              color="primary"
            >
              Update class position
            </Button> */}
            <Button
              style={{ marginLeft: '16px' }}
              size="md"
              variant="contained"
              onClick={close}
              color="primary"
            >
              Done
            </Button>
          </div>
        </div>

        <RemoveClass
          open={openRemove}
          handleClose={handleCloseRemove}
          removeClass={removeClass}
          className={classData.class}
        />
      </Dialog>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
    updateDataBase: (coll, doc, data) => dispatch(updateDataBase(coll, doc, data)),
  };
};

const mapStateToProps = state => {
  return {
    PublicClasses: state.firestore.data.PublicClasses,
    proposalsData: state.firestore.data.proposals,
    selectedPhase: state.proposals.selectedPhase,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'PublicClasses' }])
)(withSnackbar(withStyles(styles)(ManageClasses)));
