import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { filterProposals } from '../../redux/actions/proposalsActions';
import * as searchHelper from '../../util/searchHelper';
import MultipleSelect from '../../inputs/MultipleSelect';

const PublicTags = ({
  filterProposals,
  history,
  PublicTags,
  proposals,
  selectedPhase,
}) => {
  const [name, setName] = React.useState([]);

  React.useEffect(() => {
    const { search } = history.location;

    if (!search) setName([]);
  }, [history.location]);

  React.useEffect(() => {
    const { search } = history.location;

    if (search.public_tags) {
      const publicTags = search.public_tags.split(',');
      setName(publicTags);
    }
  }, [history.location]);

  const handleChange = event => {
    const { value } = event.target;

    if (value[value.length - 1] !== 'none') setName(value);
  };

  const selectAll = publicTags => {
    const values = publicTags.map(publicTag => publicTag.name);

    setName(values);
  };

  const clearAll = () => setName([]);

  const applyFilters = () => {
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'public_tags',
        name
      ),
    });

    filterProposals(history.location.search);
  };

  const publicTags = () => {
    const firebaseDataByPhase = _.filter(
      proposals,
      proposal => proposal.phaseId === selectedPhase
    );
    const tags = PublicTags
      ? PublicTags.map(val => {
          const proposalsHaveTags = _.filter(
            firebaseDataByPhase,
            proposal => proposal.tags
          );
          const proposalsTags = _.map(proposalsHaveTags, 'tags');
          const allTags = [].concat.apply([], proposalsTags);
          const amount = _.filter(allTags, ['id', val.id]);

          return {
            name: val.name,
            amount: amount.length,
          };
        })
      : [];

    return tags;
  };

  return (
    <div id="filter-public-tags">
      <MultipleSelect
        options={publicTags()}
        values={name}
        onChange={handleChange}
        selectAll={() => selectAll(publicTags())}
        clearAll={clearAll}
        placeholder="Public tags"
        apply={applyFilters}
      />
    </div>
  );
};

PublicTags.propTypes = {
  filterProposals: PropTypes.func.isRequired,
  selectedPhase: PropTypes.string.isRequired,
  proposals: PropTypes.arrayOf(PropTypes.object),
  PublicTags: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
  return {
    PublicTags: state.firestore.ordered.PublicTags,
    proposals: state.firestore.ordered.proposals,
    selectedPhase: state.proposals.selectedPhase,
  };
};

export default compose(
  connect(mapStateToProps, { filterProposals }),
  firestoreConnect([{ collection: 'PublicTags' }, { collection: 'proposals' }])
)(withRouter(PublicTags));
