import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import ManageUser from './ManageUser';
import DeleteAlert from './DeleteAlert';

class UsersDetails extends Component {
  state = {
    isModalOpen: false,
  };

  openModal = () => {
    this.setState({isModalOpen: true});
  };
  closeModal = () => {
    this.setState({isModalOpen: false});
  };

  render() {
    const {user} = this.props;
    return (
      <Grid container alignItems="center" className="border-bottom py-1">
        <Grid item xs={3} className="h5 mb-0">
          {user.firstName} {user.lastName}
        </Grid>
        <Grid item xs={3} className="sm">
          {user.email}
        </Grid>
        <Grid item xs={3} className="sm">
          {user.proffesion}
        </Grid>
        <Grid item xs={2} className="sm">
          {user.role}
        </Grid>
        <Grid item xs={1} className="sm">
          <div className="d-flex align-items-center">
            <IconButton
              onClick={this.openModal}
              className="mr-1"
              size="small"
              aria-label="Delete"
            >
              <EditIcon fontSize="default" />
            </IconButton>
            <DeleteAlert user={user} />
          </div>
        </Grid>
        {this.state.isModalOpen && (
          <ManageUser
            close={this.closeModal}
            isOpen={this.state.isModalOpen}
            userData={user}
          />
        )}
      </Grid>
    );
  }
}

export default UsersDetails;
