import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {required, length, confirmation} from 'redux-form-validators';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '../inputs/Button';
import TextField from '../inputs/Text';
import {changePassword} from '../redux/actions/authActions';

const styles = theme => ({
  card: {
    maxWidth: '656px',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '0',
    boxSizing: 'border-box',
    padding: '20px 32px',
  },
  cardContnet: {
    padding: 0,
  },
  password: {
    marginBottom: 0,
    lineHeight: '34px',
    paddingTop: 3,
  },
});

class Profile extends Component {
  state = {
    edit: false,
    password: '',
    newPassword: '',
    confPassword: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  toggleChangePassword = () => {
    this.setState({edit: !this.state.edit});
  };

  handleChangePassword = values => {
    const {password, newPassword} = values;
    this.props.changePassword(password, newPassword);
    this.setState({edit: !this.state.edit});
  };

  render() {
    const {classes, auth, profile} = this.props;
    const {edit} = this.state;
    return (
      <form
        className="fit-height d-flex align-items-center"
        onSubmit={this.props.handleSubmit(this.handleChangePassword)}
      >
        <Grid container justify="center">
          <Card className={classes.card}>
            <CardContent className="p-0 mb-3">
              <h2 className="mb-3">Change password</h2>
              <p className="sm mb-3">
                Body L. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor.
              </p>

              <Grid className="mb-2" container alignItems="center" spacing={2}>
                <Grid item xs={12} md={6}>
                  <div className="sm text-md-right">Name, Surname</div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5 className="mb-0">
                    {profile.firstName} {profile.lastName}
                  </h5>
                </Grid>
              </Grid>

              <Grid className="mb-2" container alignItems="center" spacing={2}>
                <Grid item xs={12} md={6}>
                  <div className="sm text-md-right">Email</div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5 className="mb-0">{auth.email}</h5>
                </Grid>
              </Grid>

              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={6}>
                  <div className="sm text-md-right mt-1">Password</div>
                </Grid>
                <Grid item xs={12} md={4}>
                  {!edit && <h5 className={classes.password}>* * * * * * * *</h5>}
                  {edit && (
                    <Field
                      component={TextField}
                      size="md"
                      id="password"
                      name="password"
                      placeholder="Password..."
                      type="password"
                      validate={[required()]}
                      validation
                    />
                  )}
                </Grid>
              </Grid>

              {edit && (
                <div>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="sm text-md-right mt-1">New password</div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={TextField}
                        size="md"
                        id="newPassword"
                        name="newPassword"
                        placeholder="Password..."
                        type="password"
                        validate={[required(), length({min: 8})]}
                        validation
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="sm text-md-right mt-1">Password confirmation</div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={TextField}
                        size="md"
                        id="confPassword"
                        name="confPassword"
                        placeholder="Password..."
                        type="password"
                        validate={[
                          required(),
                          confirmation({
                            field: 'newPassword',
                            fieldLabel: 'the new password',
                          }),
                        ]}
                        validation
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </CardContent>
            <div className="d-flex justify-content-end p-0 mb-2">
              {!edit && (
                <Button
                  onClick={this.toggleChangePassword}
                  size="md"
                  variant="contained"
                  color="primary"
                >
                  Change password
                </Button>
              )}

              {edit && (
                <div className="d-flex">
                  <Button
                    style={{marginRight: '8px'}}
                    onClick={this.toggleChangePassword}
                    size="md"
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" size="md" variant="contained" color="primary">
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Card>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (creds, creds2) => dispatch(changePassword(creds, creds2)),
  };
};

const ProfileForm = reduxForm({
  form: 'ProfileForm', // a unique identifier for this form
})(Profile);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProfileForm));
