import React, { useCallback } from 'react';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import * as Helper from '../util/Helper.js';
import TableStructure from './TableStructure';

const useStyles = makeStyles({
  class: {
    width: '7.5rem',
    fontWeight: 700,
    fontSize: '0.75rem',
  },
  classBox: {
    padding: '6px 4px 4px',
    textAlign: 'center',
    color: '#fff',
  },
  classHolder: {
    paddingTop: 5,
  },
});

function InfoTable({ Data, proposalsOrdered, PublicClasses }) {
  const classes = useStyles();

  const classValueHtml = useCallback(
    value => (
      <div className={classes.class}>
        {value && (
          <div className={classes.classBox} style={{ backgroundColor: value.colour }}>
            {value.class}
          </div>
        )}
      </div>
    ),
    [classes.class, classes.classBox]
  );

  const spaceBetweenThousands = value =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  const columns = React.useMemo(
    () => [
      {
        Header: 'Proposals', // Unique key and cannot remove
        columns: [
          {
            Header: 'No.',
            accessor: 'no',
          },
          {
            Header: 'Pseudonym',
            accessor: 'pseudonym',
          },
          {
            Header: 'Class',
            accessor: 'proposalClass',
            disableSorting: true,
            Cell: ({ cell: { value } }) => classValueHtml(value),
          },
        ],
      },
      {
        Header: 'Info', // Unique key and cannot remove
        columns: [
          {
            Header: 'Competition area',
            accessor: 'competition',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Land area',
            accessor: 'land',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'of which filled areas on existing water area',
            accessor: 'landOnWater',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Water area',
            accessor: 'water',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Block for construction',
            accessor: 'construction',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Public green areas and parks',
            accessor: 'parks',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Gfa for housing',
            accessor: 'housing',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Gfa for business and offices',
            accessor: 'offices',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Gfa for public services',
            accessor: 'publicServices',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Gfa for other uses',
            accessor: 'otherUses',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Waste water treatment plant',
            accessor: 'wasteWater',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Electricity of the tramway',
            accessor: 'electricityTramway',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Total gross floor area',
            accessor: 'floorArea',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Vehicle parking',
            accessor: 'vehicleParking',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Bicycle parking',
            accessor: 'bicycleParking',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Residents',
            accessor: 'residents',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Jobs',
            accessor: 'jobs',
            Cell: ({ cell: { value } }) => spaceBetweenThousands(value),
          },
          {
            Header: 'Density',
            accessor: 'density',
          },
        ],
      },
    ],
    [classValueHtml]
  );

  const formatedStatisticValue = value => {
    let values = value
      .split(' ')
      .filter(
        value =>
          value !== 'm2' &&
          value !== 'gfm2' &&
          value !== 'spaces' &&
          value !== 'persons' &&
          value !== 'jobs'
      );
    let strippedValue = values.join('');
    const floatVal = parseFloat(strippedValue);

    // Replace , with .
    if (strippedValue.indexOf(',') >= 0) {
      strippedValue = strippedValue.replace(/,/g, '.');
    }

    // Only keep 2 decimal
    if (floatVal && !Number.isInteger(floatVal)) {
      strippedValue = Number(strippedValue).toFixed(2);
    }

    return strippedValue;
  };

  const generateArrayOfSingleRowObj = useCallback(() => {
    let singleRowObj = {};
    const initialStatisticsValueObj = {
      competition: '',
      land: '',
      landOnWater: '',
      water: '',
      construction: '',
      parks: '',
      housing: '',
      offices: '',
      publicServices: '',
      otherUses: '',
      wasteWater: '',
      electricityTramway: '',
      floorArea: '',
      vehicleParking: '',
      bicycleParking: '',
      residents: '',
      jobs: '',
      density: '',
    };
    let statisticsValueObj = { ...initialStatisticsValueObj };

    return _.map(Data, (value, key) => {
      const { number, name, excelData } = value;
      const { statistic } = excelData;
      const proposalClass = Helper.getProposalClass(key, proposalsOrdered, PublicClasses);

      let proposalNoNameObj = {
        no: number,
        pseudonym: name,
      };

      // Add class into 'Class' column
      if (proposalClass) {
        proposalNoNameObj = {
          ...proposalNoNameObj,
          proposalClass: proposalClass,
        };
      }

      // Only get statistic value from these keys
      if (statistic && statistic.length > 0) {
        if (
          statistic[0].hasOwnProperty('Competition area ') ||
          statistic[0].hasOwnProperty('Competition area')
        ) {
          statisticsValueObj = {
            competition: statistic[0]['Competition area ']
              ? formatedStatisticValue(statistic[0]['Competition area '])
              : formatedStatisticValue(statistic[0]['Competition area']),
            land: formatedStatisticValue(statistic[1]['Land area']),
            landOnWater: formatedStatisticValue(
              statistic[2]['of which filled areas on the existing water area']
            ),
            water: statistic[3]['Water area ']
              ? formatedStatisticValue(statistic[3]['Water area '])
              : formatedStatisticValue(statistic[3]['Water area']),
            construction: formatedStatisticValue(
              statistic[4]['Block areas (for construction)']
            ),
            parks: formatedStatisticValue(statistic[5]['Public green areas and parks']),
            housing: formatedStatisticValue(statistic[6]['Gross floor area for housing']),
            offices: formatedStatisticValue(statistic[7]['Gfa for business and offices']),
            publicServices: statistic[8]['Gross floor area for public services ']
              ? formatedStatisticValue(
                  statistic[8]['Gross floor area for public services ']
                )
              : formatedStatisticValue(statistic[8]['Gfa for public services']),
            otherUses: formatedStatisticValue(statistic[9]['Gfa for other uses']),
            wasteWater: formatedStatisticValue(
              statistic[10]['Waste water treatment plant']
            ),
            electricityTramway: formatedStatisticValue(
              statistic[11]['Electricity of the tramway']
            ),
            floorArea: formatedStatisticValue(statistic[12]['Total gross floor area']),
            vehicleParking: formatedStatisticValue(
              statistic[13]['Vehicle parking, total']
            ),
            bicycleParking: formatedStatisticValue(
              statistic[14]['Bicycle parking, total']
            ),
            residents: formatedStatisticValue(statistic[15]['Number of residents']),
            jobs: statistic[16]['Estimated number of jobs ']
              ? formatedStatisticValue(statistic[16]['Estimated number of jobs '])
              : formatedStatisticValue(statistic[16]['Estimated number of jobs']),
            density: formatedStatisticValue(
              statistic[17]['Density (total gfm2 /comp. m2)']
            ),
          };
        } else {
          statisticsValueObj = {
            ...initialStatisticsValueObj,
          };
        }
      }
      if (!statistic) {
        statisticsValueObj = {
          ...initialStatisticsValueObj,
        };
      }

      // Combine proposal's no, name and statistic value object
      singleRowObj = {
        ...proposalNoNameObj,
        ...statisticsValueObj,
      };

      return singleRowObj;
    });
  }, [Data, PublicClasses, proposalsOrdered]);

  // Only rerender when data in table is changed
  const data = React.useMemo(() => generateArrayOfSingleRowObj(), [
    generateArrayOfSingleRowObj,
  ]);

  return <TableStructure columns={columns} data={data} disableMultiSort={true} />;
}

export default InfoTable;
