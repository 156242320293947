import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  tabContainer: {
    marginLeft: 16,
    padding: 32,
    backgroundColor: theme.modeColors.navBackground,
    flex: 1,
  },
}));

const SingleInfoTab = ({
  editCompetitionInfo,
  generalInfo,
  editorState,
  changeEditorState,
  createMarkup,
  tabHeader,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.tabContainer}>
      <h3 className="mb-2 pb-1">{tabHeader}</h3>
      {editCompetitionInfo && (
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class editor_container"
          toolbar={{
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
          }}
          onEditorStateChange={changeEditorState}
        />
      )}
      {!editCompetitionInfo && generalInfo && (
        <div dangerouslySetInnerHTML={createMarkup} />
      )}
    </div>
  );
};

SingleInfoTab.propTypes = {
  editCompetitionInfo: PropTypes.bool.isRequired,
  generalInfo: PropTypes.objectOf(PropTypes.string).isRequired,
  editorState: PropTypes.object.isRequired,
  changeEditorState: PropTypes.func.isRequired,
  createMarkup: PropTypes.object.isRequired,
  tabHeader: PropTypes.string.isRequired,
};

export default SingleInfoTab;
