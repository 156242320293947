import React from 'react';
import PropTypes from 'prop-types';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Button from '../inputs/Button';
import { useTheme } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  zoomButtons: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '22px',
    },
  },
});

const Zoom = ({ zoomOut, zoomIn, zoomLevel, classes }) => {
  const theme = useTheme();

  return (
    <div className={`border d-flex ml-2 bg-white ${classes.zoomButtons}`}>
      <Button
        id="zoom-out"
        onClick={zoomOut}
        variant="contained"
        style={{
          backgroundColor: theme.modeColors.iconBtnBackground,
          borderRight: '1px solid #ccced0',
        }}
        size="xs"
        disabled={zoomLevel === 1}
      >
        <ZoomOutIcon />
      </Button>
      <Button
        id="zoom-in"
        onClick={zoomIn}
        variant="contained"
        style={{ backgroundColor: theme.modeColors.iconBtnBackground }}
        size="xs"
        disabled={zoomLevel === 6}
      >
        <ZoomInIcon />
      </Button>
    </div>
  );
};

Zoom.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  zoomOut: PropTypes.func.isRequired,
  zoomIn: PropTypes.func.isRequired,
};

export default withStyles(styles)(Zoom);
