import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { withStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';

import ExportPdf from './ExportPdf';
import Rating from '../single-proposal/Rating';
import Button from '../inputs/Button';
import * as Helper from '../util/Helper.js';

import { useTranslation } from 'react-i18next';
import FlagFI from '../assets/icons/lang-fi.png';
import FlagEN from '../assets/icons/lang-en.png';

const styles = theme => ({
  toggle: {
    width: '2rem',
    fontSize: '0.875rem',
  },
  no: {
    width: '3rem',
    fontWeight: 700,
    fontSize: '1.375rem',
    color: theme.modeColors.textColor,
  },
  pseudonym: {
    width: '7rem',
    fontWeight: 400,
    fontSize: '0.875rem',
    paddingTop: 5,
    paddingRight: 8,
    hyphens: 'auto',
    wordBreak: 'break-word',
  },
  class: {
    width: '7.5rem',
    fontWeight: 700,
    fontSize: '0.75rem',
  },
  classBox: {
    padding: '6px 4px 4px',
    width: '6.5rem',
    textAlign: 'center',
    color: '#fff',
  },
  classHolder: {
    paddingTop: 5,
  },
  chipTag: {
    height: '1.5rem',
    backgroundColor: '#fff',
    padding: '0.25rem 0.75rem 0.125rem',
    marginLeft: '0.375rem',
    marginBottom: '0.25rem',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    fontWeight: 400,
    fontSize: '0.75rem',
  },
  imageContainer: {
    width: '10rem',
    marginRight: '2rem',
  },
  keysContainer: {
    width: '20rem',
    marginRight: '2rem',
  },
  juryRating: {
    marginRight: '2rem',
  },
  publicReview: {
    flex: 1,
    marginRight: '2rem',
    fontSize: '0.75rem',
  },
  expandItem: {
    backgroundColor: theme.modeColors.expandItemBackground,
  },
  downloadBtnsFlexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifySelf: 'flex-end',
  },
});

const StyledH5 = styled('h5')(({ theme }) => ({
  color: theme.modeColors.singleProposalSideNavText,
}));

const ListItem = ({
  proposal,
  expandAll,
  classes,
  proposalClass,
  proposalPublicTags,
  proposalPrivateTags,
  proposalPublicNote,
  proposalPrivateNote,
  proposalPublicRate,
  proposalsOrdered,
  PublicClasses,
  id,
  users,
  reactAppUrl,
  selectedPhase,
}) => {
  const { t, i18n } = useTranslation();
  const printButtonsData = [
    {
      id: 'onePageNoProfile',
      label: t('PDF - 1 page - no profile'),
      isOnePagePdf: true,
      hasProfile: false,
    },
    {
      id: 'onePageWithProfile',
      label: t('PDF - 1 page - with profile'),
      isOnePagePdf: true,
      hasProfile: true,
    },
    {
      id: 'twoPageNoProfile',
      label: t('PDF - 2 page - no profile'),
      isOnePagePdf: false,
      hasProfile: false,
    },
    {
      id: 'twoPageWithProfile',
      label: t('PDF - 2 page - with profile'),
      isOnePagePdf: false,
      hasProfile: true,
    },
  ];

  const [expand, setExpand] = React.useState(false);
  const [printBtnState, setPrintBtnState] = React.useState({
    onePageNoProfile: false,
    onePageWithProfile: false,
    twoPageNoProfile: false,
    twoPageWithProfile: false,
  });
  const [language, setLanguage] = React.useState('en');

  const toggleExpand = () => {
    setExpand(!expand);
  };

  React.useEffect(() => {
    expandAll ? setExpand(true) : setExpand(false);
  }, [expandAll]);

  const gitStatistic = () => {
    if (proposal && proposal.excelData) {
      const { statistic } = proposal.excelData;

      return statistic
        ? _.map(statistic, (value, key) => {
            const statisticKey = Object.keys(value)[0];
            const statisticValue = value[Object.keys(value)[0]];
            const statisticValueArr = statisticValue.split(' ');

            if (statisticValueArr[0].indexOf(',') >= 0) {
              statisticValueArr[0] = statisticValueArr[0].replace(',', '.');
            }
            if (/^[0-9]+(\.)?[0-9]*$/.test(statisticValueArr[0])) {
              const floatVal = parseFloat(statisticValueArr[0]);
              if (floatVal && !Number.isInteger(floatVal)) {
                statisticValueArr[0] = Number(statisticValueArr[0]).toFixed(2);
              }
            }
            // Add space between thousands
            statisticValueArr[0] = statisticValueArr[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            return statisticValueArr ? (
              <div key={key} className="xs d-flex align-items-end pb-1">
                <span className="col mr-3">{statisticKey}:</span>
                <span className="col-1 text-right">{statisticValueArr.join(' ')}</span>
              </div>
            ) : null;
          })
        : null;
    }
    return null;
  };

  const pdfData = { [id]: proposal };

  const getPublicReview = lang => {
    return _.map(pdfData, (proposalData, id) => {
      const publicReview = Helper.getProposalPublicReview(proposalsOrdered, id, lang);
      // TODO: find a better solution for this problem as user copy paste text into editor with line break and there's no space between words
      const replaceBrWithSpace =
        publicReview && publicReview.includes('<br>')
          ? publicReview.split('<br>').join('&nbsp;')
          : null;
      const publicReviewArray = Helper.htmToArray(replaceBrWithSpace);

      return publicReviewArray.map((item, i) => <p key={i}>{item}</p>);
    });
  };

  return (
    <div className={`border-bottom py-2 ${expand ? classes.expandItem : ''}`}>
      <div className="d-flex align-items-start">
        <div className={classes.toggle}>
          {!expand && <ExpandMore onClick={toggleExpand} />}
          {expand && <ExpandLess onClick={toggleExpand} />}
        </div>
        <div className={classes.no}>{proposal.number}</div>
        <div className={classes.pseudonym}>{proposal.name}</div>
        <div className={classes.class}>
          {proposalClass && (
            <div
              className={classes.classBox}
              style={{ backgroundColor: proposalClass.colour }}
            >
              {proposalClass.class}
            </div>
          )}
          {!proposalClass && <div className={classes.classHolder}> No class </div>}
        </div>
        <div className="col sm">
          {proposalPublicTags.map((tag, i) => {
            return (
              <Chip
                key={tag.id}
                label={tag.name}
                classes={{
                  root: classes.chipTag,
                  label: 'xs p-0',
                }}
              />
            );
          })}
        </div>
        <div
          className={!expand && proposalPublicNote ? 'ellipsis-text col xs' : 'col xs'}
        >
          {proposalPublicNote}
        </div>
        <div className="col sm">
          {proposalPrivateTags.map((tag, i) => {
            return (
              <Chip
                key={tag.id}
                label={tag.name}
                classes={{
                  root: classes.chipTag,
                  label: 'xs p-0',
                }}
              />
            );
          })}
        </div>
        <div className="col xs">{proposalPrivateNote}</div>
      </div>
      <Collapse in={expand}>
        <div className="border-top mt-1 d-flex justify-content-start pt-1 pl-1">
          <div className={classes.imageContainer}>
            <Link className="normal-text" to={`/proposal/${id}`}>
              <img
                className="img-fluid"
                src={reactAppUrl + proposal.thumbnail_url}
                alt={proposal.name}
              />
            </Link>
          </div>

          <div className={classes.keysContainer}>
            <StyledH5>Key figures of the entry</StyledH5>
            {gitStatistic()}
          </div>

          <div className={classes.juryRating}>
            <StyledH5>Jury rating</StyledH5>
            <Rating proposal={proposal} rate={proposalPublicRate} />
          </div>

          <div className={classes.publicReview}>
            <StyledH5>Jury review</StyledH5>
            <div>{getPublicReview(language)}</div>
          </div>

          {/* Print PDF buttons */}
          <div className={`${classes.downloadBtnsFlexContainer}`}>
            {/* Language switchers */}
            <div className="d-flex align-items-center">
              {/* <StyledH5 className="mb-0 mr-2">Print PDF in: </StyledH5> */}
              <div className="pr-1">
                <button
                  type="button"
                  className={`lang-btn ${i18n.language === 'en' ? 'active' : ''}`}
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    i18n.changeLanguage('en');
                    setLanguage('en');
                  }}
                >
                  <img className="lang-img" src={FlagEN} alt="English" />
                </button>
                <button
                  type="button"
                  className={`lang-btn ${i18n.language === 'fi' ? 'active' : ''}`}
                  onClick={() => {
                    i18n.changeLanguage('fi');
                    setLanguage('fi');
                  }}
                >
                  <img className="lang-img" src={FlagFI} alt="Suomi" />
                </button>
              </div>
            </div>

            {printButtonsData.map(({ id, label, isOnePagePdf, hasProfile }) => (
              <div className="pr-1 pt-1" key={id}>
                {!printBtnState[id] && (
                  <Button
                    size="sm"
                    variant="contained"
                    color="primary"
                    onClick={() => setPrintBtnState({ [id]: true })}
                    style={{ width: '14rem' }}
                  >
                    {t('load_pdf', { label: label })}
                  </Button>
                )}

                {printBtnState[id] && (
                  <PDFDownloadLink
                    document={
                      <ExportPdf
                        data={pdfData}
                        proposalsOrdered={proposalsOrdered}
                        PublicClasses={PublicClasses}
                        users={users}
                        isOnePagePdf={isOnePagePdf}
                        reactAppUrl={reactAppUrl}
                        selectedPhase={selectedPhase}
                        hasProfile={hasProfile}
                      />
                    }
                    fileName={`${proposal.number} - ${proposal.name} - ${label}.pdf`}
                  >
                    {({ blob, url, loading, error }) => (
                      <Button
                        size="sm"
                        variant="contained"
                        color="primary"
                        style={{ width: '14rem' }}
                      >
                        {loading
                          ? t('Loading document...')
                          : t('export_pdf', { label: label })}
                      </Button>
                    )}
                  </PDFDownloadLink>
                )}
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    reactAppUrl: state.proposals.envValues.reactAppUrl,
    selectedPhase: state.proposals.selectedPhase,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ListItem));
