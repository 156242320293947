import React, {Component} from 'react';
import {styled} from '@material-ui/styles';
import Button from '../inputs/Button';
import TextField from '../inputs/Text';

const styles = {
  root: {
    position: 'relative',
  },
  saveButton: {
    position: 'absolute',
    bottom: '12px',
    right: '8px',
  },
  textModeColors: {},
};

const StyledH5 = styled('h5')(({theme}) => ({
  color: theme.modeColors.singleProposalSideNavText,
}));

class Notes extends Component {
  state = {
    activeEdit: false,
  };

  handleOnFocus = () => {
    setTimeout(() => this.setState({activeEdit: true}), 400);
  };

  handleOnBlur = () => {
    setTimeout(() => this.setState({activeEdit: false}), 300);
  };

  componentDidMount() {
    const {text} = this.props;
    if (text) {
      this.setState({text});
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.text !== this.props.text) {
      this.setState({text: nextProps.text});
    }
  }

  handleChange = e => {
    this.setState({text: e.target.value});
  };

  createMarkup = text => {
    if (text) {
      const newtext = text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
      return {__html: newtext};
    }
    return null;
  };

  render() {
    const {title, edit} = this.props;
    const {activeEdit, text} = this.state;
    return (
      <div style={styles.root}>
        <StyledH5 className="mb-1">{title}</StyledH5>

        {edit && (
          <TextField
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            size="md"
            multiline
            rows="5"
            value={text ? text : ''}
            onChange={this.handleChange}
            readOnly={!edit}
            placeholder="Add notes ..."
          />
        )}

        {!edit && text && (
          <div className="sm" dangerouslySetInnerHTML={this.createMarkup(text)} />
        )}
        {!edit && !text && (
          <div className="sm text-gray"> The secretary will add public notes</div>
        )}

        {activeEdit && edit && (
          <Button
            onClick={() => this.props.onSave(text)}
            style={styles.saveButton}
            size="xs"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        )}
      </div>
    );
  }
}

export default Notes;
