import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_PRIMARY_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_PRIMARY_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_PRIMARY_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_PRIMARY_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_PRIMARY_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_PRIMARY_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_PRIMARY_ID,
};

const firebaseSecondaryConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_ID,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export const secondaryApp = firebase.initializeApp(firebaseSecondaryConfig, 'Secondary');

export default firebase;
