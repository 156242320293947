import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {withStyles} from '@material-ui/core/styles';

import Text from './Text';

const styles = theme => ({
  menuItem: {
    margin: '4px 8px',
    padding: '4px 8px',
  },
});

const MultipleSelect = ({value, options, onChange, classes, fixedWidth}) => (
  <Select
    displayEmpty
    value={value}
    onChange={onChange}
    input={
      <Text
        style={{minWidth: fixedWidth ? '200px' : ''}}
        id="select-multiple-checkbox"
        size="md"
      />
    }
    classes={{
      root: classes.root,
    }}
  >
    {options.map(option => (
      <MenuItem
        disableGutters={true}
        key={option.value}
        value={option.value}
        classes={{
          root: classes.menuItem,
        }}
      >
        <div className="d-flex full-width align-items-center">
          <div className="sm">{option.label}</div>
        </div>
      </MenuItem>
    ))}
  </Select>
);

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(MultipleSelect);
