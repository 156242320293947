import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Button from '../inputs/Button';
import Dialog from '@material-ui/core/Dialog';
import Rating from '@material-ui/lab/Rating';
import RadioIcon from '@material-ui/icons/Lens';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import * as Helpers from '../util/Helper';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    backgroundColor: theme.modeColors ? theme.modeColors.inputBackground : '#fff',
  },
  rating_field: {
    fontSize: 14,
    paddingTop: '0.25rem',
    borderBottom: '1px solid #ecedee',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '2rem',
  },
  infoToolTip: {
    padding: '16px',
    fontSize: '14px',
  },
});

const infoIconContent = (
  <>
    <p>{'“Please, give rating for each topic which you are instructed to rate.”'}</p>
    <p>
      <i>
        {
          'Note: it is important to give rating to every proposal coherently. Topics that are left unrated will not affect the calculation of average value.'
        }
      </i>
    </p>
  </>
);

const StyledRating = withStyles({
  iconEmpty: {
    color: '#c7d1ff',
  },
  iconFilled: {
    color: '#394eb4',
  },
  iconHover: {
    color: '#394eb4',
  },
})(Rating);

const StyledRating2 = withStyles({
  iconEmpty: {
    color: '#bdbdbd',
  },
  iconFilled: {
    color: '#767676',
  },
  iconHover: {
    color: '#767676',
  },
})(Rating);

class ManageRatings extends React.Component {
  state = {
    rate: {},
  };

  componentDidUpdate() {
    const { proposalPrivateRate } = this.props;

    if (!_.isEqual(proposalPrivateRate, this.state.rate) && !this.props.isOpen) {
      this.setState({ rate: proposalPrivateRate });
    }
  }

  componentDidMount() {
    const { proposalPrivateRate } = this.props;

    if (!_.isEqual(proposalPrivateRate, this.state.rate) && !this.props.isOpen) {
      this.setState({ rate: proposalPrivateRate });
    }
  }

  getRatingFields = () => {
    const { fields, classes, proposalAverageRate, secretary } = this.props;
    const { rate } = this.state;

    return fields.map((item, i) => {
      const handleChangeRating = (i, e, newValue) => {
        const newRate = { ...rate };
        newRate[item.name] = newValue;
        this.setState({ rate: newRate });
      };
      return (
        <div key={i}>
          <div className={classes.rating_field}>
            <div style={{ minWidth: 285 }}>
              <span>{item.label}</span>
            </div>

            {proposalAverageRate && secretary && (
              <div>
                <StyledRating2
                  name={item.label}
                  value={proposalAverageRate[item.name] || 0}
                  precision={0.1}
                  icon={<RadioIcon fontSize="small" />}
                  readOnly
                />
              </div>
            )}

            <div>
              <StyledRating
                name={item.label}
                value={rate[item.name] || 0}
                precision={1}
                onChange={(e, newValue) => handleChangeRating(i, e, newValue)}
                onClick={e => this.handleClickRemoveRate(e)}
                icon={<RadioIcon fontSize="small" />}
              />
            </div>
          </div>
          {i === 0 && <div className={classes.rating_field}></div>}
        </div>
      );
    });
  };

  closeAndRest = () => {
    const { proposalPrivateRate } = this.props;
    this.setState({ rate: proposalPrivateRate });
    this.props.close();
  };

  onSave = () => {
    const { rate } = this.state;
    this.props.onSave(rate);
    this.props.close();
  };

  clearAll = () => {
    this.setState({ rate: {} });
  };

  handleClickRemoveRate = e => {
    const { rate } = this.state;
    const targetEleName = e.target.getAttribute('name')
      ? e.target.getAttribute('name')
      : null;
    const targetEleValue = parseInt(e.target.getAttribute('value'));

    if (rate && targetEleName && targetEleValue) {
      _.forEach(rate, (value, key) => {
        if (targetEleName === Helpers.formatRateName(key) && targetEleValue === value) {
          this.setState({
            rate: {
              ...rate,
              [key]: null,
            },
          });
        }
      });
    }
  };

  render() {
    const {
      title,
      isOpen,
      classes,
      proposal,
      proposalAverageRate,
      secretary,
    } = this.props;
    return (
      <Dialog
        onClose={this.closeAndRest}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="sm"
        fullWidth={true}
        classes={{ paper: classes.dialogPaper }}
      >
        <div className="p-3">
          <div className="mb-2">
            <div className="d-flex justify-content-between align-items-start">
              <h2>{title} </h2>
              <Tooltip
                title={infoIconContent}
                aria-label={infoIconContent}
                placement="left-start"
                TransitionComponent={Zoom}
                classes={{ tooltip: classes.infoToolTip }}
              >
                <InfoOutlineIcon />
              </Tooltip>
            </div>
            <h3>Proposal {proposal.number}</h3>
          </div>

          {proposalAverageRate && secretary && (
            <div className="d-flex justify-content-end sm">
              <div style={{ width: 126 }}>The average</div>
              <div style={{ width: 100 }}>Jury rating</div>
            </div>
          )}

          <div className=" border-top">{this.getRatingFields()}</div>
          <div className="d-flex justify-content-end mt-2">
            <Button onClick={this.clearAll} size="xs" color="primary">
              Clear all ratings
            </Button>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button
              size="md"
              variant="outlined"
              color="primary"
              onClick={this.closeAndRest}
            >
              Cancel
            </Button>
            <Button
              size="md"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
              onClick={this.onSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ManageRatings);
