import React, {useEffect, useRef} from 'react';
import lottie from 'lottie-web';

const Spinner = () => {
  const spinnerContainer = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: spinnerContainer.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/spinner.json', // the path to the animation json
    });
  });

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '200px',
        height: '200px',
      }}
      ref={spinnerContainer}
    />
  );
};

export default Spinner;
