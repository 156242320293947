import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '../inputs/Button';
import Dialog from '@material-ui/core/Dialog';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '../inputs/Text';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    width: 432,
    backgroundColor: theme.modeColors.inputBackground,
  },
  chipTag: {
    height: 24,
    backgroundColor: '#fff',
    padding: '0 8px',
    marginRight: 8,
    marginTop: 8,
    fontSize: '0.75rem',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
  },
  deleteIcon: {
    marginLeft: 10,
  },
});

class ManageTags extends React.Component {
  state = {
    tag: '',
  };

  handleChange = e => {
    this.setState({tag: e.target.value});
  };

  onCreateTag = tag => {
    this.props.onCreate(tag);
    this.setState({tag: ''});
  };

  render() {
    const {
      close,
      isOpen,
      classes,
      removeTag,
      removeTagFromProposal,
      addTagToProposal,
      tags,
      proposalTags,
      proposal,
    } = this.props;
    const {tag} = this.state;
    return (
      <Dialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="md"
        classes={{paper: classes.dialogPaper}}
      >
        <div className="p-3">
          <h2>Manage tags </h2>
          <h3>Proposal {proposal.number}</h3>

          <div className="border-bottom pb-3">
            {proposalTags
              ? proposalTags.map(data => (
                  <Chip
                    key={data.id}
                    label={data.name}
                    onDelete={() => removeTagFromProposal(data.id)}
                    classes={{
                      root: classes.chipTag,
                      label: 'xs p-0',
                      deleteIcon: classes.deleteIcon,
                    }}
                    deleteIcon={<CloseIcon fontSize="small" />}
                  />
                ))
              : null}
          </div>
          <div className="py-2">
            <h5 className="mb-1">Create and add a new tag</h5>
            <div className="d-flex align-items-center">
              <TextField
                value={tag}
                onChange={this.handleChange}
                className="mb-1"
                id="email"
                placeholder="Text"
                size="md"
              />
              <Button
                style={{marginLeft: '16px', marginBottom: '3px', padding: '7px 12px 5px'}}
                size="md"
                variant="outlined"
                color="primary"
                onClick={() => this.onCreateTag(tag)}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="py-2 border-bottom">
            <h5 className="mb-0">Add existing tag</h5>
            <List>
              {tags.map(data => {
                return (
                  <ListItem
                    onClick={() => {
                      addTagToProposal(data);
                      this.setState({tag: ''});
                    }}
                    key={data.id}
                    button
                  >
                    <div className="sm">{data.name}</div>
                    <ListItemSecondaryAction>
                      <Tooltip
                        classes={{tooltip: classes.tooltip}}
                        title="Remove the tag"
                      >
                        <IconButton
                          onClick={() => removeTag(data.id)}
                          size="small"
                          aria-label="Delete"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </div>
          <div className="d-flex align-items-center justify-content-end pt-2">
            <Button
              style={{marginLeft: '16px'}}
              size="md"
              variant="contained"
              onClick={close}
              color="primary"
            >
              Done
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ManageTags);
