import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import {
  signIn,
  isSignInWithEmailLink,
  resetAuthEroor,
} from '../redux/actions/authActions';
import { required, email, length } from 'redux-form-validators';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '../inputs/Button';
import TextField from '../inputs/Text';
import LoginWithLink from './LoginWithLink';
import ResetPassword from './ResetPassword';
import TurkuLogo from '../assets/icons/Turku_logo.png';

const styles = theme => ({
  card: {
    maxWidth: '544px',
    width: '100%',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '0',
    padding: '50px 80px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 40px',
    },
  },
  cardContnet: {
    padding: 0,
  },
  logo: {
    width: '140px',
    height: '70px',
    // marginLeft: '-17px',
    marginBottom: '20px',
    paddingRight: '10px',
  },
});

class Login extends Component {
  state = {
    email: '',
    password: '',
    emailLinkModal: false,
    resetPasswordModal: false,
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.authError !== this.props.authError) {
      if (!!nextProps.authError) {
        this.props.enqueueSnackbar('Incorrect email or password!', { variant: 'error' });
        this.props.resetAuthEroor();
      }
    }
  }

  handleLogin = values => {
    this.props.signIn(values);
  };

  componentDidMount() {
    const isSignInWithEmailLink = this.props.isSignInWithEmailLink(window.location.href);
    if (isSignInWithEmailLink) {
      this.setState({ emailLinkModal: true });
    }
  }

  openResetPasswordModal = () => this.setState({ resetPasswordModal: true });
  closeResetPasswordModal = () => this.setState({ resetPasswordModal: false });

  closeEmilLinkModal = () => this.setState({ emailLinkModal: false });

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY;
    const { classes, pristine, submitting } = this.props;
    return (
      !readOnly && (
        <form
          onSubmit={this.props.handleSubmit(this.handleLogin)}
          className="d-flex align-items-center justify-content-center full-height"
        >
          <Card className={classes.card}>
            <CardContent className="p-0 mb-3">
              <div className="d-flex align-items-center flex-column justify-content-center mb-4">
                <img className={classes.logo} src={TurkuLogo} alt="Turku Åbo logo" />
                <h1 className="mb-0">Judging tool</h1>
              </div>

              <h2>Log in</h2>
              {/* <p>
              Body L. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor.
            </p> */}
              <Field
                component={TextField}
                className="mb-2"
                id="email"
                name="email"
                placeholder="Text"
                size="lg"
                label="Email"
                validate={[email()]}
                validation
              />

              <Field
                component={TextField}
                id="password"
                name="password"
                placeholder="Text"
                type="password"
                size="lg"
                label="Password"
                validate={[required(), length({ min: 8 })]}
                validation
              />
            </CardContent>
            <div className="d-flex justify-content-between p-0">
              <Button size="md" color="primary" onClick={this.openResetPasswordModal}>
                Forgot password?
              </Button>
              <Button
                size="md"
                variant="contained"
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Done
              </Button>
            </div>
          </Card>
          <LoginWithLink
            close={this.closeEmilLinkModal}
            isOpen={this.state.emailLinkModal}
          />
          <ResetPassword
            close={this.closeResetPasswordModal}
            isOpen={this.state.resetPasswordModal}
          />
        </form>
      )
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
    isSignInWithEmailLink: href => dispatch(isSignInWithEmailLink(href)),
    resetAuthEroor: () => dispatch(resetAuthEroor()),
  };
};

const LoginForm = reduxForm({
  form: 'LoginForm', // a unique identifier for this form
})(withSnackbar(withStyles(styles)(Login)));

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
