import OpenSeaDragon from 'openseadragon';

let viewer = null; //Viewer is saved here as well for internal changes

//Default openseadragon settings
const viewerDefOptions = {
  zoomInButton: 'zoom-in',
  zoomOutButton: 'zoom-out',
  animationTime: 0.5,
  preserveViewport: false,
  showNavigationControl: true,
  showHomeControl: false,
  showFullPageControl: false,
};

//Cookie states
let lastCookie = document.cookie;
let currentCookie = document.cookie;
let animedByInput = false;
let haveSetCookie = false;

//Cookies are checked for changes with this interval
const cookieCheckingInterval = 256; // run every 256 ms

//State sharing mode for setting correct state mode each time the viewer is rebuilt
let stateSharingMode = false;

//The handle of the cookie changing state function
let intervalId = '';

//Cookie is checked for changes here
const checkCookie = () => {
  currentCookie = document.cookie;

  //If changes detected and not set by this client. Then change viewer pan and zoom
  if (viewer != null && currentCookie !== lastCookie) {
    lastCookie = currentCookie; // store latest cookie
    if (!haveSetCookie) {
      let newPoint = JSON.parse(getCookie('panPoint'));
      let zoomLevel = getCookie('zoomLevel');

      viewer.viewport.panTo(newPoint, true);
      viewer.viewport.zoomTo(zoomLevel, true);
    } else {
      haveSetCookie = false;
    }
  }
};

//check for Ctrl+Alt+å key presses to toggle state sharing mode
const keyDownTextField = e => {
  if (e.ctrlKey && e.altKey && e.key === 'å') {
    stateSharingMode = !stateSharingMode;
    stateSharingMode
      ? console.log('State sharing mode on')
      : console.log('State sharing mode off');
    setStateSharingMode(stateSharingMode);
  }
};

//Get cookie value by key
const getCookie = cookieName => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

//Add openseadragon events
const addEvents = () => {
  viewer.addHandler(
    'canvas-scroll',
    event => {
      //console.log('canvas-scroll');
      animedByInput = true;
    },
    { passive: true }
  );

  viewer.addHandler(
    'canvas-enter',
    event => {
      //console.log('canvas-enter');
      animedByInput = true;
    },
    { passive: true }
  );

  viewer.addHandler(
    'canvas-click',
    event => {
      //console.log('canvas-click');
      animedByInput = true;
    },
    { passive: true }
  );

  viewer.addHandler(
    'canvas-drag-end',
    event => {
      //console.log('canvas-drag-end');
      animedByInput = true;
    },
    { passive: true }
  );

  viewer.addHandler(
    'animation-finish',
    event => {
      if (animedByInput) {
        const centerPan = viewer.viewport.getCenter(true);
        const zoom = viewer.viewport.getZoom(true);

        haveSetCookie = true;
        animedByInput = false;

        document.cookie = `panPoint=${JSON.stringify(centerPan)};`;
        document.cookie = `zoomLevel=${zoom};`;
      }
    },
    { passive: true }
  );
};

//Remove openseadragon events
const removeEvents = () => {
  viewer.removeAllHandlers('canvas-scroll');
  viewer.removeAllHandlers('canvas-enter');
  viewer.removeAllHandlers('canvas-click');
  viewer.removeAllHandlers('animation-finish');
};

//Set state changing on and off here
const setStateSharingMode = modeOn => {
  if (viewer != null) {
    if (modeOn) {
      addEvents();
      intervalId = window.setInterval(checkCookie, cookieCheckingInterval);
    } else {
      removeEvents();
      clearInterval(intervalId);
    }
  }
};

/*** ONLY THIS INITIALIZATION FUNCTION IS CALLED INTERNALLY ***/
document.addEventListener('keydown', keyDownTextField, { passive: true });

//Convenience function for creating viewers
export const newViewer = options => {
  const stateDependantOptions = {};

  //Actually construction new Viewer object
  viewer = new OpenSeaDragon.Viewer({
    ...viewerDefOptions,
    ...options,
    ...stateDependantOptions,
  });

  //Set correct events based on state sharing mode
  setStateSharingMode(stateSharingMode);

  return viewer;
};

export const updateViewer = options => {
  const container = document.getElementsByClassName('openseadragon-container')[0] || null;

  if (!container) return newViewer(options);

  //Changing to different parent div
  if (options.id) {
    document.getElementById(options.id).appendChild(container);
  }

  //The tileSources naming convention is different when addTiledImage is called
  if (options.tileSource) {
    options.tileSources = options.tileSource;
  }

  viewer.open(options.tileSources, 0);
};
