import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

const StyledLink = styled('a')(({ isUrlEmpty }) => ({
  marginRight: '20px',
  padding: '0.7rem 1rem 0.5rem',
  color: '#fff',
  backgroundColor: isUrlEmpty ? '#bfbfbf' : '#3f51b5',
  fontSize: '0.875rem',
  pointerEvents: isUrlEmpty ? 'none' : 'auto',
  cursor: isUrlEmpty ? 'default' : 'pointer',
  fontWeight: 700,
  textDecoration: 'none',
  wordBreak: 'break-word',
}));

const KerrokantasiExternalLink = ({ proposalId, PublicKerrokantasi }) => {
  const [values, setValues] = useState({
    btnLabel: 'Kerrokantasi',
    url: '',
  });

  useEffect(() => {
    const getProposalKerrokantasiValues = proposalId => {
      if (PublicKerrokantasi) {
        const currProposal = PublicKerrokantasi[proposalId] || {};

        currProposal && currProposal.kerrokantasi
          ? setValues(currProposal.kerrokantasi)
          : setValues({
              btnLabel: 'Kerrokantasi',
              url: '',
            });
      }
    };
    getProposalKerrokantasiValues(proposalId);
  }, [proposalId, PublicKerrokantasi]);

  return (
    <div className="d-flex align-items-center justify-content-between pb-2 mt-1">
      <StyledLink
        className="mb-0"
        href={values.url}
        target="_blank"
        isUrlEmpty={!values.url}
      >
        {values.btnLabel}
      </StyledLink>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    PublicKerrokantasi: state.firestore.data.PublicKerrokantasi,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'PublicKerrokantasi' }])
)(KerrokantasiExternalLink);
